import search from '../../../assets/images/Search.png'
import GreenCheck from '../../../assets/images/greenCheck.png'
import trash from '../../../assets/images/trash.png'
import notes from '../../../assets/images/notes.png'
import fillArrow from '../../../assets/images/fillArrow.png'
import addicon from '../../../assets/images/Addicon.png'
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import "./index.css"
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

function Expert({ setForExpert }) {
  const [expert, setExpert] = useState([]);
  const [newExpert, setNewExpert] = useState({
    certified: ""
  });


  const { user } = useSelector((state) => state.userInfo)


  useEffect(() => {
    if (user && user.skill) {
      let oldSkills = user?.skill?.expert;
      if (oldSkills) {
        setExpert(oldSkills);
      }
    }
  }, [user]);

  useEffect(() => {
    setForExpert(expert)
  }, [expert])

  const updateExpert = (e) => {
    let key = e.target.name;
    let val = e.target.value;
    if (key === "certified") {
      val = val === "true";
    }

    if (key === "handsOnExperineceInYears" || key === "totalExperinceInYears") {
      val = parseInt(val)
    }
    setNewExpert((prev) => ({ ...prev, [key]: val }));
  };

  const updateOldExpert = (e, index) => {
    const key = e.target.name;
    let val = e.target.value;
    if (key === "certified") {
      val = val === "true";
    }
    let newExpert = expert.map((it, ind) => {
      if (ind === index) {
        it[key] = val;
      }
      return it;
    });
    setExpert(newExpert);
  };

  const addNew = () => {
    if (
      !newExpert.skill ||
      !newExpert?.handsOnExperineceInYears ||
      !newExpert?.totalExperinceInYears ||
      newExpert?.certified === ""
    ) {
      return toast.error("Enter all the details!")
    }

    setExpert((prev) => [...prev, newExpert]);
    setNewExpert({
      skill: "",
      handsOnExperineceInYears: "",
      totalExperinceInYears: "",
      certified: ""
    });
  };

  const editExpert = (ind, isEdit) => {
    let newExpert = expert.map((item, i) => {
      let newIt = { ...item }
      if (i === ind) {

        newIt.isEditing = isEdit;
      }
      return newIt;

    })
    setExpert(newExpert);
  };

  const removeExpert = (index) => {
    setExpert(expert.filter((exp, ind) => ind !== index));
  };

  return (
    <>
      <Typography className="table-lable-head">Skill I am expert in</Typography>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Skills</TableCell>
            <TableCell align="left">Hands-on Experience</TableCell>
            <TableCell align="left">Training Experience</TableCell>
            <TableCell align="left">Certified </TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" component="th" scope="row">
              <div className="areaSearch">
                <input
                  type="text"
                  style={{ maxWidth: "100px" }}
                  class="form-control forBorder skillInput"
                  id="exampleInputPassword1"
                  placeholder="Skills"
                  name="skill"
                  value={newExpert?.skill}
                  onChange={updateExpert}
                />

                <img src={search} alt="search Icon" />
              </div>
            </TableCell>
            <TableCell align="left">
              <div className="areaSearch">
                <input
                  type="number"
                  class="form-control forBorder skillInput"
                  id="exampleInputPassword1"
                  placeholder="Years of experience"
                  name="handsOnExperineceInYears"
                  value={newExpert?.handsOnExperineceInYears}
                  onChange={updateExpert}
                />
              </div>
            </TableCell>
            <TableCell align="left">
              <div className="areaSearch">
                <input
                  type="number"
                  class="form-control forBorder skillInput"
                  id="exampleInputPassword1"
                  placeholder="Years of experience"
                  name="totalExperinceInYears"
                  value={newExpert?.totalExperinceInYears}
                  onChange={updateExpert}
                />
              </div>
            </TableCell>
            <TableCell align="left">
              <select
                name="certified"
                value={newExpert?.certified}
                onChange={updateExpert}
                class="form-control forBorder"
              >
                <option value="" disabled selected hidden>Yes/No</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>

            </TableCell>
            <TableCell align="left">
              <img
                src={addicon}
                className="blueCirImg"
                alt="add Icon"
                onClick={addNew}
              />
            </TableCell>
          </TableRow>
          
          {expert.map((item, ind) => (
            <TableRow className="arrow">
              {item?.isEditing ? (
                <TableCell>
                  
                  <div className="areaSearch">
                    <input
                      type="text"
                      class="form-control forBorder skillInput"
                      id="exampleInputPassword1"
                      placeholder="Skills"
                      name="skill"
                      value={item?.skill}
                      onChange={(e) => updateOldExpert(e, ind)}
                    />
                  </div>
                </TableCell>
              ) : (
                <TableCell>{item?.skill}</TableCell>
              )}

              {item?.isEditing ? (
                <TableCell>
                  <div className="areaSearch">
                    <input
                      type="number"
                      class="form-control forBorder skillInput"
                      id="exampleInputPassword1"
                      placeholder="Years of experience"
                      name="handsOnExperineceInYears"
                      value={item?.handsOnExperineceInYears}
                      onChange={(e) => updateOldExpert(e, ind)}
                    />
                  </div>
                </TableCell>
              ) : (
                <TableCell>
                  {item?.handsOnExperineceInYears} yrs
                  {/* <span>
                    <img src={topDownArrow} alt="down Icon" />
                  </span> */}
                </TableCell>
              )}

              {item?.isEditing ? (
                <TableCell>
                  <div className="areaSearch">
                    <input
                      type="number"
                      class="form-control forBorder skillInput"
                      id="exampleInputPassword1"
                      placeholder="Years of experience"
                      name="totalExperinceInYears"
                      value={item?.totalExperinceInYears}
                      onChange={(e) => updateOldExpert(e, ind)}
                    />
                  </div>
                </TableCell>
              ) : (
                <TableCell>
                  {item?.totalExperinceInYears} yrs

                </TableCell>
              )}

              {item?.isEditing ? (
                <TableCell>
                  <select
                    name="certified"
                    onChange={(e) => updateOldExpert(e, ind)}
                    class="form-control"
                    value={item?.certified}
                  >
                    <option value="" disabled selected hidden>Yes/No</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </TableCell>
              ) : (
                <TableCell>
                  {item?.certified ? "Yes" : "No"}
                </TableCell>
              )}
              <TableCell className="">
                {item?.isEditing ? (
                  <img
                    src={GreenCheck}
                    alt="green check"
                    onClick={() => editExpert(ind, false)}
                  />
                ) : (
                  <img
                    src={notes}
                    alt="note"
                    onClick={() => editExpert(ind, true)}
                  />
                )}
                <img
                  src={trash}
                  alt="trash Icon"
                  onClick={() => removeExpert(ind)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default Expert