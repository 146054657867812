import React from 'react'

import ClipLoader from "react-spinners/ClipLoader";
import "./index.css"

function FullScreenLoader({ color, loading = false, style = {}, size = 30 }) {
  return (
    <div className='fullScreenWidth'>
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={style}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default FullScreenLoader