import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap'
import Expert from './Expert'
import Client from './Client'
import Knowledge from './Knowledge'
import { useDispatch, useSelector } from "react-redux";
import { previousStep } from "../../../redux/features/stepSlice";
import { apiAction, updateSkillsAction } from "../../../redux/actions/trainerAction";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";
import { Stack, Container, Grid, TextField, Typography } from "@mui/material";

import "./index.css"

function Skills() {

  const dispatch = useDispatch();

  const [forExpert, setForExpert] = useState([])
  const [forKnowledge, setForKnowledge] = useState([])
  const [forClient, setForClient] = useState([])
  const [totalExperience, setTotalExperience] = useState();
  const [trainingExperience, setTrainingExperience] = useState();
  const [isLoading, setIsLoading] = useState("");
  const { skills } = useSelector((state) => state.stepInfo);
  const previous = () => {
    dispatch(previousStep())
  }

  const { user } = useSelector((state) => state.userInfo)

  useEffect(() => {

    if (user) {
      setTotalExperience(user?.profile?.totalExperience)
      setTrainingExperience(user?.profile?.trainingExperience);

    }
  }, [user])


  const saveSkills = () => {
    let skillsData = {
      totalExperience,
      trainingExperience,
      expert: forExpert,
      knowledge: forKnowledge,
      client: forClient
    };

    dispatch(updateSkillsAction(skillsData, () => {
      toast.success("Skills updated successfully!")
    }))
    // dispatch(
    //   apiAction()
    // );

  }

  useEffect(() => {
    setIsLoading(skills);
  }, [skills]);

  console.log("expert", forExpert);
  console.log("knowledge", forKnowledge)
  console.log("clients", forClient)


  return (
    <Stack className="skillDiv">
      <Grid container md={12} className="skillDivRow">
        <Grid item md={5} xs={12} className="experience" style={{ paddingRight: "0px" }}>
          <Typography className='SkillExperience'>Total Experience</Typography>

          <TextField size="small"
            type="text"
            class="form-control forwidth"
            id="exampleInputEmail1"
            value={totalExperience}
            aria-describedby="emailHelp"
            onChange={(e) => setTotalExperience(e.target.value)}
          />
        </Grid>
        <Grid md={5} xs={12} className="experience" style={{ paddingLeft: "0px" }}>
          <Typography className='SkillExperience'>Training Experience</Typography>
          <TextField size="small"
            type="text"
            class="form-control forwidth"
            id="exampleInputEmail1"
            value={trainingExperience}
            aria-describedby="emailHelp"
            onChange={(e) => setTrainingExperience(e.target.value)}
          />
        </Grid>
      </Grid>
      <Stack spacing={3}>
        <Stack >
          <Expert setForExpert={setForExpert} />
        </Stack>
        <Stack>
          <Knowledge setForKnowledge={setForKnowledge} />
        </Stack>
        <Stack>
          <Client setForClient={setForClient} />
        </Stack>
      </Stack>

      <Stack className="s-n-btn free_SN_btn" direction={'row'} mt={3} justifyContent='end'>
        <Stack>
          <button
            class="btn btn-outline prvs-btn"
            onClick={() => previous()}
            type="button"
          >
            Previous
          </button>

        </Stack>
        <Stack>
          <button
            class="btn s-n-button forHover"
            onClick={() => saveSkills()}
            type="button"
          >
            {isLoading ? <Loader color="white" loading={true} /> : "Save & Next"}
          </button>
        </Stack>
      </Stack>

    </Stack >
  );
}

export default Skills