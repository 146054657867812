import moment from "moment";
import { toast } from "react-toastify";
import axios from "../../axios";
import { loadingStep, nextStep } from "../features/stepSlice";
import { setAvailability } from "../features/trainerSlice";
import { getProfile } from "./userAction";

export const addBasicInfoAction =
  (basicInfo, onSuccess) => async (dispatch) => {
    try {
      dispatch(loadingStep({ step: "basicInfo", state: true }));
      const { data } = await axios.post("/profile", basicInfo);
      if (data.success) {
       
        onSuccess&&onSuccess();
        dispatch(getProfile());
       
      }
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    } catch (error) {
      //console.log(error);
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    }
  };

  export const sendOTP =
  (basicInfo, onSuccess) => async (dispatch) => {
    console.log("send otp api call");
    try {
      dispatch(loadingStep({ step: "basicInfo", state: true }));
      const { data } = await axios.post("/verify/sendOTP", basicInfo);
      if (data.success) {    
        onSuccess&&onSuccess();
        dispatch(getProfile());  
            
      } else{
        toast.error(data.msg)
      }
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    } catch (error) {
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    }
  };

  export const verifyOTP =
  (basicInfo, onSuccess) => async (dispatch) => {
    console.log("send otp api call");
    try {
      dispatch(loadingStep({ step: "basicInfo", state: true }));
      const { data } = await axios.post("/verify/verifyOTP", basicInfo);
      if (data.success) {    
        onSuccess&&onSuccess(data.msg);
        dispatch(getProfile());    
          
      } else{
        toast.error(data.msg)
     }
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    } catch (error) {
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    }
  };

export const addProfSummAction = (info, onSuccess) => async (dispatch) => {
  try {
    dispatch(loadingStep({ step: "professional", state: true }));
    const { data } = await axios.post("/profile", info);
    if (data.success) {
      toast.success("Profile Updated successfully");
      dispatch(getProfile());
      dispatch(nextStep());
    }
    dispatch(loadingStep({ step: "professional", state: false }));
  } catch (error) {
    dispatch(loadingStep({ step: "professional", state: false }));
  }
};

export const uploadProfileImgAction =
  (formData, onSuccess) => async (dispatch) => {
    try {
      dispatch(loadingStep({ step: "profileStat", state: true }));
      const { data } = await axios.post("/profile/uploadImage", formData);
      if (data.success) {
        onSuccess && onSuccess()
        dispatch(getProfile());
      }
      dispatch(loadingStep({ step: "profileStat", state: false }));
    } catch (error) {
      dispatch(loadingStep({ step: "profileStat", state: false }));
    }
  };


export const uploadCoverImgAction = (formData, onSuccess) => async (dispatch) => {
  try {
    dispatch(loadingStep({ step: "profileCover", state: true }));
    const { data } = await axios.post("/profile/uploadCoverImage", formData);
    if (data.success) {
      onSuccess && onSuccess();
      dispatch(getProfile());
    }
    dispatch(loadingStep({ step: "profileCover", state: false }));
  } catch (error) {
    dispatch(loadingStep({ step: "profileCover", state: false }));
  }
};

export const updateCommEngAction = (comm, onSuccess) => async (dispatch) => {
  try {
    dispatch(loadingStep({ step: "community", state: true }));
    const { data } = await axios.post("/communityEngagement", {
      communityEngagement: comm,
    });

    if (data.success) {
      dispatch(getProfile());
      onSuccess && onSuccess();
    }
    dispatch(loadingStep({ step: "community", state: false }));
  } catch (error) {
    dispatch(loadingStep({ step: "community", state: false }));
  }
};


export const updateSkillsAction =(skillInfo,onSuccess)=>async (dispatch)=>{
  try{
    dispatch(loadingStep({ step: "skills", state: true }));
    const { data } = await axios.post("/skillsAndExperience", skillInfo);
      if (data.success) {
        dispatch(getProfile());
        onSuccess && onSuccess();
        dispatch(apiAction())
      }
    dispatch(loadingStep({ step: "skills", state: false }));
  }
  catch(err){
dispatch(loadingStep({ step: "skills", state: false }));
  }
}

export const apiAction = (info, onSuccess) => async (dispatch) => {
  dispatch(loadingStep({ step: "skills", state: true }));
  dispatch(nextStep());
  dispatch(loadingStep({ step: "skills", state: false }));
};


export const getAvailability = () => async (dispatch) => {
  try {
    let { data:{availability} } = await axios.get("/availability");
    dispatch(setAvailability(availability));

  } catch (error) {
      console.log(error)
  }
};

export const addAvailability = (body) => async (dispatch) => {
  try {
    await axios.post("/availability", body);
    // dispatch(setAvailability(availability));
  } catch (error) {
    console.log(error);
  }
};

export const removeAvailability = (body) => async (dispatch) => {
  try {
    let { data } = await axios.delete("/availability", body);
    
    console.log(data);
    // dispatch(setAvailability(availability));
  } catch (error) {
    console.log(error);
  }
};