import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSap6B0l5DHx8AUd_uAZVlKTRcfHJqvMY",
  authDomain: "zencroacher.firebaseapp.com",
  projectId: "zencroacher",
  storageBucket: "zencroacher.appspot.com",
  messagingSenderId: "1009355925700",
  appId: "1:1009355925700:web:96314e117e9f8049ce8191",
  measurementId: "G-Y3HZ2T97PW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);



