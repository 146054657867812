import { toast } from "react-toastify";
import axios from "../../axios";
import { loadingStep, nextStep } from "../features/stepSlice";
import { getProfile } from "./userAction";

export const addStudentBasicInfoAction =
  (basicInfo, onSuccess) => async (dispatch) => {
    try {
      dispatch(loadingStep({ step: "basicInfo", state: true }));
      const { data } = await axios.post("/profile", basicInfo);
      if (data.success) {
        
        dispatch(getProfile());
        onSuccess&&onSuccess()
        
      }
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    } catch (error) {
      //console.log(error);
      dispatch(loadingStep({ step: "basicInfo", state: false }));
    }
  };


export const loadTimezone =
  (url, onSuccess) => async (dispatch) => {
    try {
      const { data } = await axios.get(url);
      console.log(data);
      if (data.success) {
        onSuccess && onSuccess()
      }
    } catch (error) {
    }
  };