import React from 'react'
import StudentStats from '../../components/student/studentsStats/StudentStats'
import StudentTabs from '../../components/student/studentTabs/StudentTabs'
import simpleUpdate from '../../assets/images/studentUpdateCover.png';
import { useDispatch } from "react-redux";
import { Container, Grid, Stack } from '@mui/material';
import { getProfile } from "../../redux/actions/userAction";
import './index.css';

function Student() {

    const dispatch = useDispatch();

    return (
        <Container style={{ maxWidth: '1440px' }}>
            <Grid container md={12} mt={5}>
                <Stack className='studentHead'>
                    <a style={{ textDecoration: 'none', color: 'white', padding: '10px', marginLeft: '30px' }} href='/#'>Home</a>
                </Stack>
                <Stack>
                    <img width={'100%'} src={simpleUpdate} alt="simple" />
                </Stack>
            </Grid>
            <Grid container mt={-8}>
                <Grid item md={4} pr={2} pl={10}>
                    <StudentStats />
                </Grid>
                <Grid item md={8} pr={10} pl={2}>
                    <StudentTabs />
                </Grid>
            </Grid>
        </Container >
    )
}

export default Student