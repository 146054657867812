import { useState, useEffect } from 'react'
import tips from '../../../assets/images/tipsImg.png'
import addicon from '../../../assets/images/Addicon.png'
import deleteIcon from '../../../assets/images/deleteIcon.png'
import { useDispatch, useSelector } from "react-redux";
import { previousStep } from "../../../redux/features/stepSlice";
import { addAvailability, apiAction, getAvailability } from "../../../redux/actions/trainerAction";
import moment from 'moment';
import Slider from "../../Slider/Slider";
import Switch from '@mui/material/Switch';
import Loader from "../../loader/Loader";
import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import './index.css';

const range = { start: 7, end: 21, interval: 1, minimumTimeDifference: 30 };    // minimumTimeDifference in minutes

function Availability({ labels, btnTypeSubmit, onSubmit }) {
  const dispatch = useDispatch();
  const previous = () => {
    dispatch(previousStep());
  };

  const [localState, setLocalState] = useState({
    isDynamicSchedule: false,
  });

  useEffect(() => {
    dispatch(getAvailability());
  }, []);

  const { professional } = useSelector((state) => state.stepInfo);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(professional);
  }, [professional]);



  const { availability } = useSelector((state) => state.trainerInfo);
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (!availability[0]?.days) return;
    let newAvailability = [...availability].map((avail) => ({
      ...avail,
      days: avail.days.map((day) => ({
        ...day,
        slots: day.slots.map((slot) => ({
          start: moment(slot.start),
          end: moment(slot.end),
        })),
      })),
    }));
    console.log(newAvailability);
    setDays((prev) => [...prev, ...newAvailability[0].days]);
  }, [availability]);

  const dayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const shortDayNames = dayNames.map((d) => d.substring(0, 3));
  const getCurrentSlot = () => {
    return {
      days: dayNames.map((d) => {
        return { dayName: d, selected: false };
      }),
      anyTime: false,
      start: moment().set({ hour: 16, minute: 0, second: 0, millisecond: 0 }),
      end: moment().set({ hour: 19, minute: 0, second: 0, millisecond: 0 }),
    };
  };
  const [currentSlot, setCurrentSlot] = useState(getCurrentSlot());

  const updateCurrentSlotDay = (day) => {
    if (day.selected) {
      setLocalState((prev) => ({
        ...prev,
        isWeeklyAvailable: false,
      }));
    }
    let isWeeklyAvailable = [];
    let existingDays = currentSlot.days.map((d) => {
      if (d.dayName === day.dayName) {
        d.selected = !d.selected;
      }
      if (d.selected) isWeeklyAvailable.push(true);
      return d;
    });
    setCurrentSlot((prev) => ({
      ...prev,
      days: existingDays,
    }));

    //enable "Available all day checkbox" on all days selected
    if (isWeeklyAvailable.length === currentSlot.days.length)
      setLocalState((prev) => ({
        ...prev,
        isWeeklyAvailable: true,
      }));
  };

  const updateDynamicSchedule = () => {
    setLocalState((prev) => ({
      ...prev,
      isDynamicSchedule: !prev.isDynamicSchedule,
    }));
  };

  const updateCurrentSlotAnyTime = () => {
    setCurrentSlot((prev) => ({
      ...prev,
      isAnytime: !prev.isAnytime,
    }));
  };

  const updateWeeklyAvailable = () => {
    var selected = !localState.isWeeklyAvailable;
    setLocalState((prev) => ({
      ...prev,
      isWeeklyAvailable: !prev.isWeeklyAvailable,
    }));

    var existingDays = currentSlot.days.map((d) => {
      return {
        ...d,
        selected,
      };
    });
    setCurrentSlot((prev) => ({
      ...prev,
      days: existingDays,
    }));
  };

  const saveSkills = () => {
    dispatch(apiAction());
  };

  const dispacthSlots = (updatedDays) => {
    const slots = [];
    updatedDays.forEach((day) => {
      day.slots.forEach((slot) => {
        slots.push({ ...day, ...slot });
      });
    });
    dispatch(addAvailability({ ...localState, days: slots }));
  };
  const onAddSlot = () => {
    const updatedDays = [...days];
    for (var i = 0; i < dayNames.length; i++) {
      var isSelecetd = currentSlot.days.find(
        (c) => c.dayName === dayNames[i] && c.selected
      );
      if (isSelecetd) {
        // eslint-disable-next-line no-loop-func
        var dayIndex = updatedDays.findIndex((d) => d.dayName === dayNames[i]);
        if (dayIndex >= 0) {
          updatedDays[dayIndex].slots = updatedDays[dayIndex].slots || [];
          updatedDays[dayIndex].slots.push({
            start: currentSlot.start,
            end: currentSlot.end,
          });
        } else {
          updatedDays.push({
            day: dayNames[i].substring(0, 3),
            dayName: dayNames[i],
            slots: [{ start: currentSlot.start, end: currentSlot.end }],
          });
        }
      }
    }

    setDays(updatedDays);

    setCurrentSlot((prev) => ({
      ...prev,
      ...getCurrentSlot(),
    }));

    dispacthSlots(updatedDays);
  };

  const removeSlot = (day, { aEnd, aStart }) => {
    const dayData = days.find((d) => d.day === day);
    let slots = dayData.slots || [];

    slots = slots.map((s) => {
      return {
        ...s,
        aStart: convertToValue(s.start.format("H:mm")),
        aEnd: convertToValue(s.end.format("H:mm")),
      };
    });

    slots = slots.filter((s) => aStart !== s.aStart && aEnd !== s.aEnd);
    const updatedDays = days.map((p) => {
      if (p.day === day) {
        p.slots = slots;
      }
      return p;
    });
    setDays(updatedDays);
    dispacthSlots(updatedDays);
  };

  const getDayBox = (day) => {
    const trs = [];
    const dayData = days.find((d) => d.day === day);
    var slots = [];
    if (dayData && (dayData.slots || []).length > 0) {
      slots = dayData.slots || [];
      slots = slots.map((s) => {
        return {
          ...s,
          day,
          aStart: convertToValue(s.start.format("H:mm")),
          aEnd: convertToValue(s.end.format("H:mm")),
        };
      });
    }

    var isFirst = true;
    for (var i = range.start; i <= range.end;) {
      var toAdd = (
        <td>
          <div style={{ textAlign: "center", padding: "6px 0px" }}></div>
        </td>
      );
      var start = convertToValue(i);
      // var end = convertToValue(i + range.interval);
      let findSlot = slots.find((s1) => {
        return start >= s1.aStart && start <= s1.aEnd;
      });
      if (findSlot) {
        var percentage =
          ((findSlot.aEnd > 12 ? findSlot.aEnd - 12 : findSlot.aEnd) - start) /
          range.interval;
        percentage = Math.min(1, percentage) * 100;
        var st = {
          color: "white",
          padding: "6px 0px",
          background: "#39CE36",
          width: percentage + "%",
          height: "100%",
        };
        if (percentage < 100) {
          st.borderRadius = `0% ${percentage}% ${percentage}% 0%`;
        }
        var text = "";
        if (isFirst) {
          text = (
            <img
              style={{ cursor: "pointer" }}
              onClick={(e) => removeSlot(day, findSlot)}
              src={deleteIcon}
              alt="delete Icon"
            />
          );
          st.color = "white";
        } else {
          text = "s";
          st.color = "#39CE36";
        }
        toAdd = (
          <td
            valign="top"
            style={{ textAlign: "center", border: "none", height: "100%" }}
          >
            <div style={st}>{text}</div>
          </td>
        );
        isFirst = false;
      } else {
        isFirst = true;
      }
      trs.push(toAdd);
      i = i + range.interval;
    }
    return trs;
  };

  const convertToValue = (time) => {
    if (time.toString().indexOf(":") >= 0) {
      const values = time.split(" ")[0].split(":");
      if (values[1] != null) {
        return parseFloat(values[0]) + parseFloat(values[1] / 60);
      } else {
        return parseFloat(values[0]);
      }
    } else {
      return time;
    }
  };

  return (
    <>

      <Grid container md={12} className="comments" direction={'row'} justifyContent='space-between'>

        <Grid item md={4}>
          <Typography className="weekly">{labels.title}</Typography>

          <Stack direction={'row'} alignItems='center'>
            <Switch defaultChecked
              checked={localState.isDynamicSchedule}
              onChange={(e) => updateDynamicSchedule()}
              id="flexSwitchCheckDefault"
            />
            <label
              className="form-check-label weeklyLabel"
              htmlFor="flexSwitchCheckDefault"
            >
              {labels.checkBoxTitle}
            </label>
          </Stack>
        </Grid>
        <Grid className='description' item md={5} direction={'row'}>
          <Stack direction={'row'}>
            <Stack>
              <img src={tips} alt="tips" />
            </Stack>
            <Typography fontSize={'12px'}>
              Professional Title (if relevant) + Key Experiences (with the total
              number of years worked) + Top Skills/Expertise/Unique Values
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      {localState.isDynamicSchedule ? (
        <></>
      ) : (
        <>
          <Stack className="firstGraph h-auto forPadding">
            <Stack className="table-responsive table_padding">
              <Stack className='tableborder'>
                <table >
                  <thead className='availabilityTavHead'>
                    <tr >
                      <th></th>
                      {heads.map((h) => {
                        return (
                          <th style={{ fontSize: '12px', fontWeight: '300', color: '#5D5B70' }}>
                            {h.h}:00 {h.t}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="availabilityTav dateTr">
                    {shortDayNames.map((day) => {
                      return (
                        <tr className="dateThead">
                          <td style={{ height: "30px", color: '#5D5B70', fontSize: '14px' }}>{day}</td>
                          {getDayBox(day)}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Stack>
            </Stack>
          </Stack>

          <Typography className="weekly newWeekly">Add Weekly Availability</Typography>

          <div class="form-check form-switch">

            <Stack direction={'row'} alignItems='center'>
              <Switch defaultChecked
                checked={localState.isWeeklyAvailable}
                onChange={(e) => updateWeeklyAvailable()}
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
              />
              <label
                className="form-check-label weeklyLabel"
                htmlFor="flexSwitchCheckDefault"
              >
                I am Available all day of the week (Except Public Holidays)
              </label>
            </Stack>
          </div>

          <Stack className='weekSelect'>
            <Grid container md={12}>
              <Grid item md={12} xs={12}>
                <Stack direction={'row'}  >
                  {currentSlot.days.map((day, key) => {
                    return (
                      <Stack key={key} alignItems='center' direction={'row'} >
                        <Checkbox defaultChecked
                          checked={day.selected}
                          onChange={(e) => updateCurrentSlotDay(day)}
                          id="inlineCheckbox1"
                          value="option1"
                        />
                        <Typography className='dayName'>
                          {day.dayName}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Grid>


              <Stack direction={'row'} alignItems='center' mb={5}>
                <Switch defaultChecked
                  checked={currentSlot.isAnytime}
                  onChange={(e) => updateCurrentSlotAnyTime()}
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                />
                <label>
                  Any time between 9:00 am - 5:00 pm IST
                </label>
              </Stack>


              <Slider
                currentSlot={currentSlot}
                setCurrentSlot={setCurrentSlot}
                range={range}
              />


              <Stack className="prevent-select timeZone" direction={'row'} justifyContent='space-between'>
                <Typography>Indian Standard Time</Typography>
                <Stack justifyContent={'end'}>
                  {currentSlot.isAnytime ? (
                    <Typography>Anytime</Typography>
                  ) : (
                    <Typography>
                      {currentSlot.start.format("h:mm a")} to{" "}
                      {currentSlot.end.format("h:mm a")}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Stack>

          <Stack mt={3} pr={3}>
            <Grid container md={12}>
              <Grid md={10}></Grid>
              <Grid md={2}>
                <Stack className="s-n-btn free_SN_btn">
                  <button
                    onClick={() => onAddSlot()}
                    class="btn btn-outline prvs-btn addslot"
                    type="button"

                  >
                    <Stack direction={'row'} spacing={2} justifyContent='center' alignItems={'center'}>
                      <Stack>
                        <img className='addicon' src={addicon} alt="add Icon" />
                      </Stack>
                      <Stack>
                        Add Slot
                      </Stack>
                    </Stack>
                  </button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </>
      )}

      <Stack mt={5}>
        <Stack container md={12} direction='row' spacing={3} justifyContent='end'>
          <Stack md={2}>
            <button class="btn btn-outline prvs-btn" onClick={() => previous()} type="button">Previous</button>
          </Stack>
          <Stack md={2}>

            <button
              variant="contained"
              class="btn s-n-button forHover s-n-ava"
              onClick={btnTypeSubmit ? onSubmit : saveSkills}
              type="button"
            >
              {btnTypeSubmit ? "Submit" : "Save & Next"}
            </button>

          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default Availability

const getHeads = () => {
  var maps = [];
  for (var i = range.start; i <= range.end;) {
    var toAdd = { h: i, t: "am" };
    if (i > 12) {
      toAdd = { h: i - 12, t: "pm" };
    }
    maps.push(toAdd);
    i = i + range.interval;
  }
  return maps;
};

const heads = getHeads();