import { toast } from "react-toastify";
import axios from "../../axios"
import { loading, setUser, setToken } from "../features/userSlice";

export const loginAction = (user, onSuccess) => async (dispatch) => {
    try {
        dispatch(loading(true))
        const { data } = await axios.post("/auth/login", user);
        if (data && data.accessToken ) {    
            dispatch(setUser({ user: data.data }))
            dispatch(setToken({ token: data.accessToken,authenticated:true }));
            axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
            dispatch(getProfile());
            onSuccess && onSuccess();
        }else{
            toast.error(data.msg)
        }
        
        dispatch(loading(false));
    } catch (error) {

        dispatch(loading(false));
    }
}

export const linkedinSignInAction = (clientSecret) => async (dispatch) => {
    try {
        // dispatch(loading(true))

        fetch("https://www.linkedin.com/oauth/v2/accessToken", {
          method: "POST",
          headers: {
            "Content-Type": "x-www-form-urlencoded",
          },
          body: clientSecret,
        });

        // const data  = await axios.post(
        //     "https://www.linkedin.com/oauth/v2/accessToken",
        //     clientSecret
        // );

    } catch (error) {
        dispatch(loading(false));
    }
}


export const getProfile = () => async (dispatch) => {
    const { data } = await axios.get("/profile");
    console.log("data", data);
    const user  = data.userData;
    // user.profile = user.profileData || {};
    // user.community = user.community || [];
    dispatch(setUser({ user }))
}