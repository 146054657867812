import './App.css';

import Sign from './pages/auth/Sign';
import { Routes, Route } from "react-router-dom";
import Profile from "./pages/trainer/Profile"
import Layout from './components/layout/Layout';
import Student from './pages/student/Student';
// import { LinkedInCallback } from "react-linkedin-login-oauth2";
import "react-toastify/dist/ReactToastify.css";
import { useIdleTimer } from "react-idle-timer";
import { useEffect, useState } from 'react';
import ProtectedRoute from './components/route/ProtectedRoute';
import { setToken } from './redux/features/userSlice';
import { useDispatch } from 'react-redux';

function App() {
  const [remaining, setRemaining] = useState(0);
  const dispatch = useDispatch()

  const onIdle = () => {
    dispatch(setToken({ token: null, authenticated: false }))
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 60 * 1000 * Number(process.env.REACT_APP_IDLE_TIME),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route exact path="/student" element={<ProtectedRoute />}>
          <Route exact path="/student" element={<Student />} />
        </Route>
        <Route exact path="/trainer" element={<ProtectedRoute />}>
          <Route exact path="/trainer" element={<Profile />} />
        </Route>
        {/* <Route exact path="/linkedin" element={<LinkedInCallback />} /> */}
      </Route>
      <Route path="/" element={<Sign />} />
    </Routes>
  );
}

export default App;
