import moment from "moment";
import React, { useEffect, useState } from "react";
import DoubleSlider from "./DoubleSlider";

const Slider = ({ currentSlot, setCurrentSlot, range }) => {
  const [times, setTimes] = useState(getTimes(range));
  const [sliderValue, setSliderValue] = useState({});

  const getTimeDiv = (t) => {
    if (t.classNameToApply.indexOf("big") >= 0) {
      return (
        <div
          className="prevent-select"
          style={{
            position: "absolute",
            marginTop: "50px",
            fontSize: "14px",
            color: "#A1A1A0",
          }}
        >
          <p style={{ marginLeft: "-7px" }}> {t.h.format("hh")}</p>
        </div>
      );
    } else {
      return <div style={{ position: "absolute", marginTop: "25px" }}> </div>;
    }
  };

  const changeTime = (selectedTime, fromSlider = false) => {
    let slot = { start: currentSlot.start, end: currentSlot.end };
    if (fromSlider) {
      const start = times[selectedTime[0]].h,
        end = times[selectedTime[1]].h;
      setCurrentSlot((prev) => ({
        ...prev,
        end,
        start,
      }));
      slot = { start, end };
    } else {
      let newTime = selectedTime.h,
        index = "min"; //moment(`${selectedTime.h} ${selectedTime.t}`, 'hh:mm a')
      if (newTime.isBefore(currentSlot.end)) {
        setCurrentSlot((prev) => ({
          ...prev,
          start: newTime,
        }));
        slot.start = newTime;
      } else {
        setCurrentSlot((prev) => ({
          ...prev,
          end: newTime,
        }));
        slot.end = newTime;
        index = "max";
      }
      const sliderVal = findTimeSlotIndex(times, newTime);
      setSliderValue({ name: index, value: sliderVal });
    }
    setTimes((prev) => [
      ...prev.map((p) => {
        let mm = p.h.format("mm").toString();
        if (p.h.isSameOrAfter(slot.start) && p.h.isSameOrBefore(slot.end)) {
          if (mm === "0" || mm === "00") {
            p.classNameToApply = "coloured_big colored";
          } else {
            p.classNameToApply = "coloured_small colored";
          }
        } else {
          if (mm === "0" || mm === "00") {
            p.classNameToApply = "big";
          } else {
            p.classNameToApply = "small";
          }
        }
        return p;
      }),
    ]);
  };

  useEffect(() => {
    changeTime({ h: currentSlot.start });
    // changeTime({ h: currentSlot.end });
  }, []);

  useEffect(() => {
    const colored = document.getElementsByClassName("colored");
    if (colored.length) {
      Array.from(colored).forEach((item) =>
        item.classList.remove("scale-tool-tip")
      );
      colored[0].classList.add("scale-tool-tip");
      colored[colored.length - 1].classList.add("scale-tool-tip");
    }
  }, [times]);

  const getDragValue = (vals) => {
    changeTime(vals, true);
  };
  return (
    <>
      <div style={{ width: "fit-content", position: "relative",paddingLeft:'.5rem' }}>
        <div className="scale_gap">
          {times.map((t) => {
            return (
              <div
                time={t.h.format("h:mm A")}
                onClick={() => changeTime(t)}
                style={{ cursor: "pointer" }}
                className={t.classNameToApply}
              >
                {getTimeDiv(t)}
              </div>
            );
          })}
        </div>
        <div style={{ margin: "1.6rem 0" }}>
          <DoubleSlider
            onChange={getDragValue}
            max={times.length - 1}
            step={range.interval}
            minimumDifference={range.minimumTimeDifference / 10}
            newValue={sliderValue}
          />
        </div>
      </div>
    </>
  );
};

export default Slider;

const getTimes = (range) => {
  let times = [];
  for (let i = range.start; i <= range.end; i = i + range.interval) {
    let classNameToApply = "big";
    for (let j = 0; j <= 50; ) {
      let toAdd = {
        h: moment().set({ hour: i, minute: j, second: 0, millisecond: 0 }),
        classNameToApply: classNameToApply,
      };
      times.push(toAdd);
      j = j + 10;
      classNameToApply = "small";
      if (i === range.end) break;
    }
  }
  return times;
};

const findTimeSlotIndex = (times, targetTime) => {
  return times.findIndex(
    (time) => time.h.format("HH:mm") === targetTime.format("HH:mm")
  );
};
