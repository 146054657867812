import { useEffect, useState } from "react";
import "./styles.css";

const DoubleSlider = ({
  min = 0,
  max = 10,
  onChange = () => {},
  initialValues,
  step = 1,
  newValue,
  minimumDifference,
}) => {
  const [vals, setVals] = useState(
    initialValues || [min * 2, parseInt(max / 2)]
  );

  const getAllValues = (e) => {
    let index = 0;
    if (e.target.name === "max") index = 1;
    setVals((prev) => {
      let newVals = [...prev];
      newVals[index] = e.target.valueAsNumber;
      if (newVals[1] - newVals[0] < minimumDifference) {
        newVals = [...prev];
      }
      onChange(newVals);
      return newVals;
    });
  };

  useEffect(() => {
    if (!newValue?.name) return;
    getAllValues({
      target: {
        name: newValue.name,
        valueAsNumber: newValue.value,
        executeOnChange: newValue.executeOnChange,
      },
    });
  }, [newValue.name, newValue.value]);

  return (
    <div onChange={getAllValues} className="range-double-group">
      <input
        name="min"
        className="range-double-slider"
        type="range"
        min={min}
        max={max}
        step={step}
        value={vals[0]}
      />
      <input
        name="max"
        className="range-double-slider"
        type="range"
        min={min}
        max={max}
        step={step}
        value={vals[1]}
      />
    </div>
  );
};

export default DoubleSlider;
