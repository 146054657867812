import React from 'react'
import "../../profile/profileStats/index.css"
import greendot from '../../../assets/images/greenDot.png';
import camera from '../../../assets/images/camera.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./index.css";
import { useEffect, useState } from 'react';
import Loader from '../../loader/Loader';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadProfileImgAction } from '../../../redux/actions/trainerAction';
import { toast } from 'react-toastify';
import person from '../../../assets/images/person.png';
import { Grid, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/system';

function StudentStats() {
  const [profile, setProfile] = useState({});
  const [stats, setStats] = useState({});
  const { user } = useSelector((state) => state.userInfo);
  const { profileStat } = useSelector((state) => state.stepInfo);
  const [isLoading, setIsLoading] = useState(false);
  const uploadref = useRef(null)
  const dispatch = useDispatch()
  const handleClick = () => {
    uploadref.current.click()
  }

  useEffect(() => {
    if (user) {
      setProfile(user.profile);
      setStats(user.stats);
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(profileStat)
  }, [profileStat]);

  const fileHandler = (e) => {

    const fd = new FormData();
    let file = e.target.files[0]
    fd.append('file', file)

    dispatch(uploadProfileImgAction(fd, () => {
      toast.success("Image uploaded successfully!")
    }))
  }

  return (
    <Stack className='studentStats' style={{ padding: '30px' }}  >
      <Grid className="user_profile">
        <Grid className="ProFile" style={{ width: '165px' }}>
          {isLoading ? (
            <Stack
              style={{
                border: "1px solid lightgray",
                padding: "40px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Loader color="#2E00FF" loading={true} size={50} />
            </Stack>
          ) : profile?.displayImage ? (
            <Stack
              style={{
                padding: "0px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <img
                src={profile?.displayImage}
                style={{
                  borderRadius: "50%",
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                }}
                alt="person"
              />{" "}
            </Stack>
          ) : (
            <Stack className="UserProFile" style={{}}>
              <img src={person} alt="person" />
            </Stack>
          )}
        </Grid>
        <img src={greendot} className="studentGreendot" alt="green Dot" />

        <img
          src={camera}
          className="studentCamera"
          onClick={handleClick}
          alt="camera"
        />

        <input
          type="file"
          ref={uploadref}
          onChange={fileHandler}
          style={{ display: "none" }}
        />
      </Grid>
      <Stack className='userInfo'>
        <Typography className='user-name' >{profile?.displayName}</Typography>
        <Typography className='user-email'>{profile?.email}</Typography>
      </Stack>

      <Stack className="user-score" direction={'row'}>
        <Typography className='OverallScore'>Overall Score</Typography>
        <Stack className="circularProgress">
          <CircularProgressbar
            value={stats?.overall}
            text={`${stats?.overall}%`}
            styles={buildStyles({
              pathColor: "#2E00FF",
              textColor: "#06021C",
              trailColor: "#d6d6d6",
              textSize: "26px",
              backgroundColor: "#3e98c7",
            })}
          />
        </Stack>
      </Stack>
      <Stack className="basic-information" spacing={1}>
        <label className="form-label">
          Basic Information <Typography>{stats?.basic}%</Typography>
        </label>
        <LinearProgress value={stats?.basic} variant="determinate" />
      </Stack>
      <Stack className="basic-information" spacing={1}>
        <label className="form-label">
          Skills & Goals <Typography>{stats?.skillAndGoals}%</Typography>
        </label>
        <LinearProgress value={stats?.skillAndGoals} variant="determinate" />
      </Stack>
      <Stack className="basic-information" spacing={1}>
        <label className="form-label">
          Free Time For Learning <Typography>{stats?.freeTimeForLearning}%</Typography>
        </label>
        <LinearProgress value={stats?.freeTimeForLearning} variant="determinate" />
      </Stack>
     
    </Stack>
  );
}

export default StudentStats