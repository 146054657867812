import React from "react";
import { Outlet } from "react-router-dom";
import AppFooter from "../global/AppFooter";
import AppHeader from "../global/AppHeader";

function Layout() {
  return (
    <div>
      <AppHeader />
      <Outlet />
      <AppFooter />
    </div>
  );
}

export default Layout;
