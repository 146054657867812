import React, { useEffect, useState } from 'react'
import trash from '../../../assets/images/trash.png'
import notes from '../../../assets/images/notes.png'
import fillArrow from '../../../assets/images/fillArrow.png'
import addicon from '../../../assets/images/Addicon.png'
import Iimg from '../../../assets/images/Iimg.png'
import { useSelector } from 'react-redux';
import GreenCheck from '../../../assets/images/greenCheck.png'
import { useDispatch } from "react-redux";
import { previousStep } from "../../../redux/features/stepSlice";
import { updateCommEngAction } from "../../../redux/actions/trainerAction";
import { toast } from 'react-toastify'
import Loader from "../../loader/Loader";
import { Stack } from '@mui/system'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

function CommEngagement() {

  const [communities, setCommunities] = useState([])
  const [newCommunity, setNewCommunity] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.userInfo);
  const { community } = useSelector((state) => state.stepInfo);
  useEffect(() => {
    if (user) {
      setCommunities(user.community);
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(community);
  }, [community]);

  const updateNewCommunity = (e) => {
    const key = e.target.name;
    setNewCommunity((prev) => ({ ...prev, [key]: e.target.value }));
  }

  const updateOldCommunity = (e, index) => {
    const key = e.target.name;
    setCommunities((prev) => ([
      ...prev.map((p, pIndex) => {
        if (index === pIndex) {
          p[key] = e.target.value;
        }
        return p;
      })
    ]))
  }

  const removeOld = (index) => {
    setCommunities((prev) => ([
      ...(prev.filter((p, i) => i !== index)),
    ]))
  }

  const addNew = () => {
    if (
      !newCommunity.platform ||
      !newCommunity.user_id ||
      !newCommunity.profileUrl
    ) {
      return toast.error("Enter all the details!")
    }
    setCommunities((prev) => [...prev, newCommunity]);
    setNewCommunity((prev) => ({ ...prev, ...{ platform: '', user_id: '', profileUrl: '' } }))
  }

  const editCommunity = (index, editing) => {
    setCommunities((prev) => ([
      ...prev.map((p, pIndex) => {
        if (index === pIndex) {
          p.editing = editing;
        }
        return p;
      })
    ]))
  }

  const dispatch = useDispatch()
  const previous = () => {
    dispatch(previousStep())
  }

  const saveSkills = () => {
    // alert("api call and data save message")
    dispatch(updateCommEngAction(communities, () => {
      toast.success("Profile updated successfully!")
    }));

  }

  return (
    <Stack className='commEngage'>
      <Typography className='table-lable-head'>Community Engagement</Typography>
      <Stack className='table-responsive'>
        <Table sx={{ minWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Platform</TableCell>
              <TableCell align="left">User Id</TableCell>
              <TableCell align="left">Profile URL</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
            >
              <TableCell align="left" component="th" scope="row">
                <div className="areaSearch">
                  <input
                    type="text"
                    class="form-control forBorder skillInput"
                    id="Platform"
                    placeholder="Platform"
                    name="platform"
                    value={newCommunity.platform}
                    onChange={updateNewCommunity}
                  />
                </div>
              </TableCell>
              <TableCell align="left">
                <div className="areaSearch">
                  <input
                    type="number"
                    class="form-control forBorder skillInput"
                    id="exampleInputPassword1"
                    placeholder="User Id"
                    name="user_id"
                    value={newCommunity.user_id}
                    onChange={updateNewCommunity}
                  />

                </div>
              </TableCell>
              <TableCell align="left">
                <div className="areaSearch">
                  <input
                    type="number"
                    class="form-control forBorder skillInput"
                    id="exampleInputPassword1"
                    placeholder="Profile URL"
                    name="profileUrl"
                    value={newCommunity.profileUrl}
                    onChange={updateNewCommunity}
                  />
                </div>
              </TableCell>

              <TableCell align="left">
                <img src={addicon} className="blueCirImg" onClick={() => addNew()} alt="add Icon" />
              </TableCell>
            </TableRow>

            {communities && communities.map((community, index) => {
              return (
                <TableRow className='arrow'>
                  {community.editing ?
                    <TableCell><input
                      type="text"
                      class="form-control forBorder skillInput"
                      id="platform"
                      placeholder="Platform"
                      name="platform"
                      value={community.platform}
                      onChange={(e) => updateOldCommunity(e, index)}
                    /></TableCell>
                    : <TableCell>{community.platform}</TableCell>
                  }


                  {community.editing ?
                    <TableCell>  <input
                      type="number"
                      class="form-control forBorder skillInput"
                      id="user_id"
                      placeholder="user_id"
                      name="user_id"
                      value={community.user_id}
                      onChange={(e) => updateOldCommunity(e, index)}
                    /></TableCell>
                    : <TableCell>{community.user_id}</TableCell>
                  }

                  {community.editing ?
                    <TableCell> <input
                      type="number"
                      class="form-control forBorder skillInput"
                      id="profileUrl"
                      placeholder="profileUrl"
                      name="profileUrl"
                      value={community.profileUrl}
                      onChange={(e) => updateOldCommunity(e, index)}
                    /> </TableCell>
                    : <TableCell>{community.profileUrl}</TableCell>
                  }

                  <TableCell>

                    {community.editing ?
                      <img src={GreenCheck} onClick={(community) => editCommunity(index, false)} alt="green check" />
                      :
                      <img src={notes} onClick={(community) => editCommunity(index, true)} className="note_img" alt="note" />
                    }
                    <img src={trash} onClick={() => removeOld(index)} alt="trash Icon" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Stack>
      <Stack className='note' direction={'row'}>
        <Stack>
          <img src={Iimg} alt="disclaimer Icon" />
        </Stack>
        <Typography>
          The Display name or full name and picture in the profile links should match the name/Photo shown here.
        </Typography>
      </Stack>
      <Stack direction={'row'} mt={30} justifyContent='end'>
        <Stack>
          <button class="btn btn-outline prvs-btn" onClick={() => previous()} type="button">Previous</button>
        </Stack>
        <Stack>
          <button class="btn s-n-button forHover" onClick={() => saveSkills()} type="button">
            {isLoading ? <Loader color="white" loading={true} /> : "Submit"}
          </button>
        </Stack>
      </Stack>
    </Stack >
  )
}

export default CommEngagement