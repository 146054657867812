import React from "react";
import "react-range-slider-input/dist/style.css";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import nomap from "../../../assets/images/nomap.png";
import { qualifications } from "../../../constants/qualifications";
import { addStudentBasicInfoAction } from "../../../redux/actions/studentAction";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./index.css";
import Loader from "../../loader/Loader";
import Map from "../../map/Map";
import axios from "../../../axios";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { nextStep } from "../../../redux/features/stepSlice";
import {
  sendOTP,
  verifyOTP
} from "../../../redux/actions/trainerAction";
import Button from '@mui/material/Button';
import { Stack, Grid, MenuItem, Select, TextField, Typography, Divider } from "@mui/material";
function BasicInfoStud() {
  const dispatch = useDispatch();
  console.log("qualifications", qualifications);
  const [localState, setLocalState] = useState({
    displayName: "",
    profileType: "student",
    displayImage: "",
    email: "",
    mobile: "",
    role: "",
    languageKnown: [],
    area: {},
    timezone: "",
    travelDistanceMax: "",
    qualification: "",
    country: "",
    city: "",
    pincode: "",
  });

  //  const [address, setaddress] = useState("");
  const [locality, setlocality] = useState("");
  // const [, setcity] = useState("");
  // const [state, setstate] = useState("");
  // const [country, setcountry] = useState("");

  const { user } = useSelector((state) => state.userInfo);
  const { basicInfo } = useSelector((state) => state.stepInfo);
  const [isLoading, setIsLoading] = useState("");
  const [isMobileVerifying, setIsMobileVerifying] = useState(false);
  const [otp, setOTP] = useState("");

  useEffect(() => {
    if (user) {
      setLocalState((prev) => ({
        ...prev,
        ...user.profile,
      }));
      storeProfileStats();
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(basicInfo);
  }, [basicInfo]);

  const storeProfileStats = () => { };

  const updateUserInfo = (e) => {
    const key = e.target.name;

    if (key === "languageKnown") {
      let isPresent = localState[key].filter((item) => item === e.target.value);
      if (isPresent.length > 0) {
        return;
      }
      let newLanguages = [...localState[key], e.target.value];
      setLocalState((prev) => ({ ...prev, [key]: newLanguages }));
      return;
    }
    setLocalState((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const getPhone = (val) => {
    setLocalState((prev) => ({ ...prev, mobile: val }));
  };


  const handlePickLocationInput = (address) => {
    setlocality(address);
  };
  const handleSelect = async (address) => {
    let results = await geocodeByAddress(address);

    if (results.length) {
      let { lat, lng } = await getLatLng(results[0]);
      let { data } = await axios.post("/timezone", { lat: lat, long: lng });
      let add = results[0].formatted_address;
      setlocality(add);
      let pincode =
        results[0].address_components[results[0].address_components.length - 1]
          .long_name;
      let country =
        results[0].address_components[results[0].address_components.length - 2]
          .long_name;
      let city =
        results[0].address_components[results[0].address_components.length - 4]
          .long_name;

      setLocalState((prev) => ({
        ...prev,
        area: { lat: lat, long: lng },
        pincode,
        country,
        city,
        timezone: data.timeZoneId,
      }));
    } else {
      toast.error("Try again!!");
    }
  };
  const saveBasicInfo = () => {
    dispatch(addStudentBasicInfoAction(localState, () => {
      dispatch(nextStep());
      toast.success("Profile Updated successfully ");
    }));
  };

  useEffect(() => {
    setIsLoading(basicInfo);
  }, [basicInfo]);


  const sendMobileOTP = () => {
    toast.success("OTP has been sent to your mobile no!")
    dispatch(sendOTP(localState, () => {
    }));
  }

  const verifyMobile = () => {
    let regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    if (localState.mobile == null) {
      return toast.error("Enter valid Mobile Number");
    }

    let newMobile = "+" + localState.mobile;
    let isValid = regex.test(newMobile);
    if (!isValid) {
      return toast.error("Enter valid Mobile Number");
    }
    dispatch(addStudentBasicInfoAction(localState));
    dispatch(sendOTP({ "mobileNumber": localState.mobile }, () => {
      toast.success("OTP has been sent to your mobile no!")
    }));
    setIsMobileVerifying(true)
  }

  const onVerification = () => {
    // show failure/success message as well
    dispatch(verifyOTP({ "otp": otp }, (msg) => {
      toast.success(msg);
    }));
    setIsMobileVerifying(false)
  }


  return (
    <Stack>
      <Grid continer md={12} style={{ display: 'flex' }}>
        <Grid item md={5}>
          <Stack spacing={3}>
            <Stack className="form-group forTopMargin" spacing={1}>
              <Typography htmlFor="exampleInputPassword1">Name</Typography>
              <TextField size="small" value={localState?.displayName}
                type="text"
                className="form-control"
                id="exampleInputPassword1 outlined-basic"
                name="displayName"
                placeholder="Type here"
                onChange={updateUserInfo}
              />
            </Stack>
            <Stack spacing={1}>
              {!isMobileVerifying ?
                <Stack>
                  <Stack direction={'row'} spacing={2}>
                    <Stack md={8}>
                      <Typography >Phone Number</Typography>
                      <PhoneInput
                        className='phoneNumber'
                        country={"in"}
                        value={localState.mobile}
                        onChange={getPhone}
                      />
                    </Stack>
                    <Stack item md={4} sx={12} style={{ width: '27%' }} justifyContent={'end'} alignItems='center' className="verify-btn">
                      <button
                        class="btn forHover"
                        type="button"
                        onClick={(e) => verifyMobile()}
                      >
                        Verify
                      </button>
                    </Stack>


                  </Stack>
                </Stack> :
                <Stack className="form-group">
                  <Grid>
                    <Grid item>
                      <label htmlFor="inputEmail4">Phone Number</label>
                      <Stack className="otp-text">Verification code has been sent to {localState.mobile}</Stack>
                    </Grid>
                  </Grid>
                  <Stack direction={'row'} spacing={2}>
                    <Stack md={8}>

                      <TextField size="small"
                        type="number"
                        id="exampleInputPassword1"
                        name="OTP"
                        placeholder="OTP"
                        value={otp}
                        max="999999"
                        onChange={(e) => setOTP(e.target.value)}
                        style={{ width: '89%' }}
                      />
                    </Stack>
                    <Stack item md={4} sx={12} style={{ width: '27%' }} justifyContent={'end'} alignItems='center' className="verify-btn">
                      <button
                        class="btn forHover"
                        type="button"
                        onClick={(e) => onVerification()}
                      >
                        Verify
                      </button>
                    </Stack>

                  </Stack>
                  <Grid className="phone-row">
                    <div className="otp-verify">
                      <div onClick={(e) => sendMobileOTP()}>Resend Code</div>
                      <div onClick={(e) => setIsMobileVerifying(false)}>Edit Number</div>
                    </div>
                  </Grid>
                </Stack>
              }
            </Stack>
            <Stack className="form-group" spacing={1}>
              <Stack htmlFor="exampleInputEmail1">City</Stack>

              <TextField size='small'
                value={localState?.city}
                type="text"
                className="form-control forInputBg"
                id="exampleInputEmail1 outlined-basic"
                aria-describedby="emailHelp"
                placeholder="City"
                name="city"
                disabled={true}
                onChange={updateUserInfo}
              />
            </Stack>
            <Stack className="form-group" spacing={1}>
              <Stack htmlFor="exampleInputPassword1">Country</Stack>

              <TextField size='small'
                type="text"
                className="form-control forInputBg"
                id="exampleInputEmail1 outlined-basic"
                aria-describedby="emailHelp"
                placeholder="Country"
                name="country"
                disabled={true}
                value={localState?.country}
                onChange={updateUserInfo}
              />
            </Stack>
            <Stack className="form-group" spacing={1}>
              <Stack htmlFor="exampleInputPassword1">Pincode</Stack>

              <TextField size='small'
                value={localState?.pincode}
                type="number"
                className="form-control forInputBg"
                id="exampleInputPassword1"
                placeholder="Pincode"
                disabled={true}
                name="pincode"
                onChange={updateUserInfo}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Divider orientation="vertical" flexItem style={{ height: "100%" }} />
        </Grid>
        <Grid item md={5}>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography>Qualification</Typography>
              <Stack>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  className="form-control"
                  onChange={updateUserInfo}
                  name="qualification"
                  size='small'
                  value={localState.qualification}
                  style={{ width: '100%' }}
                >
                  {qualifications.map((q) => {
                    return <MenuItem value={q.key}>{q.value}</MenuItem>;
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack spacing={1} className="form-group">
              <Typography >Area</Typography>
              <Stack className="areaSearch" >
                <PlacesAutocomplete
                  value={locality}
                  onChange={handlePickLocationInput}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <Stack>
                      <TextField size='small'
                        {...getInputProps({
                          placeholder: "Search here",
                          className: "form-control",
                        })}
                      />

                      <Stack className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <Stack
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  )}
                </PlacesAutocomplete>
              </Stack>
            </Stack>
            <Stack spacing={1} className="form-group mapping">
              <Typography htmlFor="exampleInputPassword1">
                {localState.city} <span>{localState.pincode} {localState.country}</span>
              </Typography>
              {!(localState.area.lat && localState.area.long) ? (
                <Stack className="nomapCont">
                  <Stack style={{ height: "30%", width: "80%" }}>
                    <img
                      src={nomap}
                      style={{ width: "100%", height: "100%" }}
                      alt="no map"
                    />
                  </Stack>
                </Stack>
              ) : (
                <Map lat={localState.area.lat} lng={localState.area.long} />
              )}
            </Stack>
            <Stack spacing={1} className="form-group">
              <Stack htmlFor="exampleInputPassword1">Time Zone</Stack>

              <TextField size='small'
                value={localState?.timezone}
                onChange={updateUserInfo}
                type="text"
                id="exampleInputPassword1"
                placeholder="Timezone"
                disabled={true}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>


      <Grid container md={12} mt={5}>
        <Grid item md={10}>

        </Grid>
        <Grid item md={2}>
          <Stack item md={4} sx={12} justifyContent={'end'} alignItems='center' className="verify-btn">
            <button
              variant="contained"
              class="btn forHover"
              type="button"
              onClick={(e) => saveBasicInfo()}
            >
              {isLoading ? (
                <Loader color="white" loading={true} />
              ) : (
                "Save & Next"
              )}
            </button>
          </Stack>
        </Grid>
      </Grid>

    </Stack >
  );
}

export default BasicInfoStud;
