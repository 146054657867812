import React from 'react'
import BasicInfoStud from '../basicInfoStud/BasicInfoStud';
import SkillsStud from '../skillsStud/SkillsStud';
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "../../../redux/features/stepSlice";
import { useState, useEffect } from "react";
import Availability from '../../profile/availability/Availability';
import { Stack } from '@mui/system';
import './index.css';
import { Divider } from '@mui/material';

const tabs = [
  { title: "Basic Information", isActive: true, component: <BasicInfoStud /> },
  {
    title: "Skills & Goals",
    isActive: false,
    component: <SkillsStud />,
  },
  {
    title: "Free Time For Learning",
    isActive: false,
    component: (
      <Availability
        labels={{
          title: "My Availability For Training",
          checkBoxTitle: "I am Available all Day",
        }}
        btnTypeSubmit={true}
        onSubmit={() => alert("api call and data save message")}
      />
    ),
  },
];

function StudentTabs() {
  const { currentStep } = useSelector((state) => state.stepInfo);
  const dispatch = useDispatch()
  const [tabsToSwitch, setTabsToSwitch] = useState(tabs);


  useEffect(() => {
    if (currentStep) {
      updateTabs(currentStep)
    } else {
      updateTabs(0)
    }
  }, [currentStep]);


  const changeTabs = (ind) => {
    dispatch(setStep(ind))
  }

  const updateTabs = (ind) => {
    let newTabs = tabsToSwitch.map((tab, i) => {
      if (i === ind) {
        tab.isActive = true;
      } else {
        tab.isActive = false;
      }
      return tab;
    });
    setTabsToSwitch(newTabs);

  };

  return (
    <Stack className="forResponsive" >
      <Stack className="TabsRow" direction={'row'} spacing={5}  >
        {tabsToSwitch.map((tab, i) => (
          <Stack
            md={2}
            style={{ width: "auto", cursor: "pointer", paddingBottom: "12px", borderBottom: tab.isActive ? "3px solid #2E00FF" : "" }}
            onClick={() => changeTabs(i)}
          >
            <Stack className="Tabs">
              {tab.title}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack>
        <Divider />
      </Stack>
      <Stack padding={'30px'}>
        {tabsToSwitch.map((tab, i) => {
          if (tab.isActive) {
            return <Stack>{tab.component}</Stack>;
          } else {
            return <></>;
          }
        })}
      </Stack>
    </Stack>
  )
}

export default StudentTabs