import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

function Loader({color,loading=false,style={},size=30}) {
  return (
    <>
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={style}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
}

export default Loader