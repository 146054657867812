import React, { useEffect, useState } from 'react'
import './index.css'

import check from '../../assets/images/check.png'

const AvatarCard = (props) => {
  const [isSelected, setIsSelected] = useState(false)
  useEffect(() => {
    if (props.profile === props.index) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [props.profile]);
  return (
    <div className="avatar-block">
      <div
        className={
          "e-card e-avatar-showcase " +
          (props.selected === props.index ? "clicked-card" : "")
        }
        style={{ background: isSelected ? "#2E00FF" : "", display: "flex", justifyContent: "center", flexDirection:'column', alignItems:'center'}}
      >
        <div className="e-card-content avatar-content">
          <div className="e-avatar  e-avatar-circle">
            <img className="image" src={props.role.img} alt="avatar" />
          </div>
        </div>
        <div className="e-card-content text-content ">
          <div
            className="e-card-content-text"
            style={{ color: isSelected ? "white" : "#33313E" }}
          >
            {props.role.text}
          </div>
        </div>
      </div>
      {isSelected ? (
        <div className="check-icon">
          <img
            src={check}
            style={props.selected === props.index ? {} : { display: "" }}
            alt="avatar"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AvatarCard
