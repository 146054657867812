import React from "react";
import AvatarCard from "../../components/authentication/AvatarCard";
import { useState, useEffect } from "react";
import { SocialIcon } from "react-social-icons";
import trainee_avatar from "../../assets/images/trainee.png";
import trainer_avatar from "../../assets/images/trainer.png";
import auth_img from "../../assets/images/auth_img.png";
import googleIco from "../../assets/images/Google.png";
import linkedinIco from "../../assets/images/Linkedin.png";
import quote from "../../assets/images/quote.png";
import { useNavigate } from "react-router-dom";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../firebase";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/actions/userAction";
import { setStep } from "../../redux/features/stepSlice";
import FullScreenLoader from "../../components/fullScreenLoader/FullScreenLoader";
import { Button, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import "./index.css";


const sign_role = [
    {
        img: trainee_avatar,
        text: "Trainee",
    },
    {
        img: trainer_avatar,
        text: "Trainer",
    },
];

const Sign = () => {
    const navigate = useNavigate();
    const [clicked, setClicked] = useState(0);
    const [profile, setProfile] = useState(1);
    const clickShow = (clicked) => {
        return clicked;
    };
    const { loading } = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()
    const provider = new GoogleAuthProvider();
    var red_url = `${window.location.origin}/linkedin`;;

    const { linkedInLogin } = useLinkedIn({
        clientId: "77niuxnzauu5kf",
        redirectUri: red_url,
        onSuccess: async (code) => {
            let clientSecret = {
                grant_type: "authorization_code",
                client_id: "77niuxnzauu5kf",
                code: code,
                client_secret: "owXHOHKa9rYQllD4",
                redirect_uri: red_url,
                socialType: "linkedin",
                profileType: profile === 1 ? "student" : "tutor"
            };
            dispatch(
                loginAction(clientSecret, () => {
                    if (profile === 1) {
                        navigate("/student");
                    } else {
                        navigate("/trainer");
                    }

                    dispatch(setStep(0));
                })
            );
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const googleSignin = () => {
        signInWithPopup(auth, provider)
            .then((result) => {

                const user = result.user;

                let userInfo = {};
                if (user) {
                    userInfo.socialType = "google"
                    userInfo.profileType = profile === 1 ? "student" : "tutor"
                    userInfo.socialId = user.uid
                    userInfo.displayName = user.displayName
                    userInfo.displayImage = user.photoURL
                    userInfo.email = user.email
                }

                dispatch(loginAction(userInfo, () => {
                    if (profile === 1) {
                        navigate("/student");
                    } else {
                        navigate("/trainer");
                    }

                    dispatch(setStep(0))

                }));

            })
            .catch((error) => {

            });
    };

    useEffect(() => {
        console.log("loginn", window.location.origin);
        clickShow(clicked);
    }, [clicked]);

    const profileSelected = (option) => {
        setProfile(option);

    };

    return (
        <>
            {loading ? (
                <FullScreenLoader color="#2E00FF" loading={true} size={80} />
            ) : (
                <>
                    <Container style={{ maxWidth: '1440px' }} >
                        <Stack direction={'row'} className="ProTrainer" mt={3}>
                            <Typography className="proMark"></Typography>
                            <Typography className="pro">Pro</Typography>
                            <Typography className="trainer"> trainer </Typography>
                        </Stack>
                    </Container>
                    <Container style={{ maxWidth: '1300px' }}>
                        <Stack spacing={5} mt={10}>
                            <Stack spacing={10}>

                                <Stack spacing={-2}>
                                    <Stack className="sign-head" direction={'row'} spacing={1}>
                                        <Typography className="typo typo-welcome">Welcome to</Typography>
                                        <Typography className="typo typo-site"> Pro Trainer</Typography>
                                    </Stack>
                                    <Stack className="typo typo-subdescription">
                                        Where Learners meet trainers
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Typography className="typo typo-sign">Sign in or Signup</Typography>
                            </Stack>
                        </Stack>
                        <Grid container mt={3}>
                            <Grid md={6} sx={12} className="leftpane">
                                <Stack spacing={5}>
                                    <Stack direction={'row'} spacing={5}>
                                        <Grid style={{ width: "100%" }}>
                                            <Stack
                                                onClick={() => profileSelected(1)}
                                                onMouseDown={() => setClicked(1)}
                                            >
                                                <AvatarCard
                                                    role={sign_role[0]}
                                                    selected={clicked}
                                                    profile={profile}
                                                    index={1}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid style={{ width: "100%" }}>
                                            <Stack
                                                onClick={() => profileSelected(2)}
                                                onMouseDown={() => setClicked(2)}

                                            >
                                                <AvatarCard
                                                    role={sign_role[1]}
                                                    selected={clicked}
                                                    index={2}
                                                    profile={profile}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid md={12} >
                                            <Stack>
                                                <Divider> Width </Divider>
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid md={12}>
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Stack className="text-center">
                                                    <Button
                                                        color="error"
                                                        variant="outlined"
                                                        className="withG-btn btn"
                                                        onClick={googleSignin}
                                                    >
                                                        <img src={googleIco} alt="googleIcon" />
                                                        <span>Google</span>
                                                    </Button>
                                                </Stack>
                                                <Stack className="text-center">
                                                    <Button
                                                        variant="outlined"
                                                        className="withL-btn btn"
                                                        onClick={linkedInLogin}
                                                    >
                                                        <img src={linkedinIco} alt="linkedinIcon" />
                                                        <span>Linkedin</span>
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid md={6} sx={12} className="auth-img">
                                <img src={auth_img} className='auth-img' alt="auth" />
                            </Grid>
                        </Grid>
                        <Grid container md={12} mt={10}>
                            <Grid md={4} sx={12}></Grid>
                            <Grid md={4} sx={12} className="quote-wraper">
                                <img src={quote} alt="quote" />
                            </Grid>
                            <Grid md={4} sx={12}></Grid>
                        </Grid>
                        <Stack mt={2}>
                            <Divider />
                        </Stack>
                        <Stack >
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems="center" >
                                <Stack>
                                    <Typography className="copyParaSign">
                                        © All rights reserved by Pro Trainer in 2022
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Button>
                                        <SocialIcon
                                            url="#"
                                            network="facebook"
                                            bgColor="#17191C"
                                            className="social_Icon"
                                        ></SocialIcon>
                                    </Button>
                                    <Button className="social-btn">
                                        <SocialIcon
                                            url="#"
                                            network="twitter"
                                            fgColor="#17191C"
                                            bgColor="white"
                                            className="social_Icon"
                                        ></SocialIcon>
                                    </Button>
                                    <Button className="social-btn">
                                        <SocialIcon
                                            url="#"
                                            network="instagram"
                                            fgColor="#17191C"
                                            bgColor="white"
                                            className="social_Icon"
                                        ></SocialIcon>
                                    </Button>
                                    <Button className="social-btn link-social_Icon">
                                        <SocialIcon
                                            url="#"
                                            network="linkedin"
                                            bgColor="#17191C"
                                            className="social_Icon"
                                        ></SocialIcon>
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Container>
                </>
            )}
        </>
    );
};

export default Sign;
