import React from "react";
import Availability from "../availability/Availability";
import BasicInfo from "../basicInfo/BasicInfo";
import CommEngagement from "../commEngagement/CommEngagement";
import ProfessionalSummary from "../professionalSummary/ProfessionalSummary";
import Skills from "../skills/Skills";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { setStep } from "../../../redux/features/stepSlice";
import { Stack, Container, Grid } from "@mui/material";

const tabs = [
  { title: "Basic Information", isActive: true, component: <BasicInfo /> },
  {
    title: "Professional Summary",
    isActive: false,
    component: <ProfessionalSummary />,
  },
  { title: "Skill & Experience", isActive: false, component: <Skills /> },
  {
    title: "My Availability",
    isActive: false,
    component: (
      <Availability
        labels={{
          title: "My Weekly Availability",
          checkBoxTitle:
            "My schedule is dynamic. I can take the training when possible.",
        }}
      />
    ),
  },
  {
    title: "Community Engagement",
    isActive: false,
    component: <CommEngagement />,
  },
];

const ProfileTabs = () => {
  const { currentStep } = useSelector((state) => state.stepInfo);
  const dispatch = useDispatch()
  const [tabsToSwitch, setTabsToSwitch] = useState(tabs);

  useEffect(() => {
    if (currentStep) {
      updateTabs(currentStep)
    } else {
      updateTabs(0)
    }
  }, [currentStep]);


  const changeTabs = (ind) => {
    dispatch(setStep(ind))
  }

  const updateTabs = (ind) => {
    let newTabs = tabsToSwitch.map((tab, i) => {
      if (i === ind) {
        tab.isActive = true;
      } else {
        tab.isActive = false;
      }
      return tab;
    });
    setTabsToSwitch(newTabs);
  };

  return (
      <Stack className="profileTabsback">
          <Stack className="TabsRow">
            {tabsToSwitch.map((tab, i) => (
              <Stack
                md={2}
                className="profile_TabS"
                style={{ borderBottom: tab.isActive ? "3px solid #2E00FF" : "", display:'flex', flexDirection:'row' }}
                onClick={() => changeTabs(i)}  
              spacing={3}
              >
                <Stack className="Tabs">
                  {tab.title}
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Stack p={3}>
            {tabsToSwitch.map((tab, i) => {
              if (tab.isActive) {
                return <Stack>{tab.component}</Stack>;
              } else {
                return <></>;
              }
            })}
          </Stack>
        </Stack>
      )
}
      export default ProfileTabs