import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import search from '../../../assets/images/Search.png'
import trash from '../../../assets/images/trash.png'
import notes from '../../../assets/images/notes.png'
import fillArrow from '../../../assets/images/fillArrow.png'
import addicon from '../../../assets/images/Addicon.png'
import GreenCheck from "../../../assets/images/greenCheck.png";
import { toast } from "react-toastify"
import "./index.css"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { Stack } from "react-bootstrap"

function Knowledge({ setForKnowledge }) {
    const [knowledge, setKnowledge] = useState([]);
    const [newKnowledge, setNewKnowledge] = useState({
        undergoingTraining: ""
    });

    const { user } = useSelector((state) => state.userInfo);


    useEffect(() => {
        if (user && user.skill) {
            let oldSkills = user?.skill?.knowledge;
            if (oldSkills) {
                setKnowledge(oldSkills);
            }
        }
    }, [user]);

    useEffect(() => {
        setForKnowledge(knowledge)
    }, [knowledge])

    const updateKnowledge = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        if (key === "undergoingTraining") {
            val = val === "true";
        }
        if (key === "certificationCompleted") {
            val = parseInt(val);
        }
        setNewKnowledge((prev) => ({ ...prev, [key]: val }));
    };

    const updateOldKnowledge = (e, index) => {
        const key = e.target.name;
        let val = e.target.value;
        if (key === "undergoingTraining") {
            val = val === "true";
        }
        if (key === "certificationCompleted") {
            val = parseInt(val);
        }



        let newKnowledge = knowledge.map((it, ind) => {
            if (ind === index) {
                it[key] = val;
            }
            return it;
        });
        setKnowledge(newKnowledge);
    };

    const addNew = () => {

        if (
            !newKnowledge?.skill ||
            !newKnowledge?.certificationCompleted ||
            newKnowledge?.undergoingTraining === ""
        ) {
            return toast.error("Enter all the details!")
        }
        setKnowledge((prev) => [...prev, newKnowledge]);
        setNewKnowledge({
            skill: "",
            certificationCompleted: "", undergoingTraining: "",
        });
    };

    const editKnowledge = (ind, isEdit) => {
        let newKnowledge = knowledge.map((item, i) => {
            let newIt = { ...item };
            if (i === ind) {
                newIt.isEditing = isEdit;
            }
            return newIt;
        });
        setKnowledge(newKnowledge);
    };

    const removeKnowledge = (index) => {
        setKnowledge(knowledge.filter((knw, ind) => ind !== index));
    };

    return (
        <>
            <Typography className="table-lable-head">Skills I have knowledge in </Typography>
            <Table sx={{ minWidth: "100%" }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Skills</TableCell>
                        <TableCell align="left">Certification Completed</TableCell>
                        <TableCell align="left">Undergone Training</TableCell>
                        <TableCell align="left">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                    >
                        <TableCell align="left" component="th" scope="row">
                            <div className="areaSearch">
                                <input
                                    type="text"
                                    style={{ maxWidth: "100px" }}
                                    class="form-control forBorder skillInput"
                                    id="exampleInputPassword1"
                                    placeholder="Skills"
                                    name="skill"
                                    value={newKnowledge?.skill}
                                    onChange={updateKnowledge}
                                />
                                <img src={search} alt="search Icon" />

                            </div>
                        </TableCell>

                        <TableCell align="left">
                            <div className="areaSearch">
                                <input
                                    type="number"
                                    class="form-control forBorder skillInput"
                                    id="exampleInputPassword1"
                                    placeholder="Years of experience"
                                    name="certificationCompleted"
                                    value={newKnowledge?.certificationCompleted}
                                    onChange={updateKnowledge}
                                />

                            </div>
                        </TableCell>
                        <TableCell align="left">
                            <select
                                name="undergoingTraining"
                                onChange={updateKnowledge}
                                value={newKnowledge?.undergoingTraining}
                                class="form-control forBorder"
                            >
                                <option value="" disabled selected hidden>Yes/No</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </TableCell>
                        <TableCell align="left">
                            <img
                                src={addicon}
                                className="blueCirImg"
                                alt="add Icon"
                                onClick={addNew}
                            />
                        </TableCell>
                    </TableRow>

                    {knowledge.map((knw, ind) => (
                        <TableRow className="arrow">
                            {knw.isEditing ? (
                                <TableCell>

                                    <input
                                        type="text"
                                        style={{ maxWidth: "100px" }}
                                        class="form-control forBorder skillInput"
                                        id="exampleInputPassword1"
                                        placeholder="Skills"
                                        name="skill"
                                        value={knw.skill}
                                        onChange={(e) => updateOldKnowledge(e, ind)}
                                    />
                                   
                                </TableCell>
                            ) : (
                                <TableCell>{knw.skill}</TableCell>
                            )}

                            {knw.isEditing ? (
                                <TableCell>
                                    <input
                                        type="number"
                                        class="form-control forBorder skillInput"
                                        id="exampleInputPassword1"
                                        placeholder="Years of experience"
                                        name="certificationCompleted"
                                        value={knw.certificationCompleted}
                                        onChange={(e) => updateOldKnowledge(e, ind)}
                                        style={{ width: '200px' }}

                                    />
                                </TableCell>
                            ) : (
                                <TableCell>{knw.certificationCompleted} yrs</TableCell>
                            )}

                            {knw.isEditing ? (
                                <TableCell>
                                    <select
                                        name="undergoingTraining"
                                        value={knw.undergoingTraining}
                                        onChange={(e) => updateOldKnowledge(e, ind)}
                                        class="form-control"
                                    >
                                        <option value="" disabled selected hidden>Yes/No</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </TableCell>
                            ) : (
                                <TableCell>{knw.undergoingTraining ? "Yes" : "No"}</TableCell>
                            )}

                            <TableCell>
                                {knw.isEditing ? (
                                    <img
                                        src={GreenCheck}
                                        alt="green check"
                                        onClick={() => editKnowledge(ind, false)}
                                    />
                                ) : (
                                    <img
                                        src={notes}
                                        alt="note"
                                        onClick={() => editKnowledge(ind, true)}
                                    />
                                )}
                                <img
                                    src={trash}
                                    alt="trashIcon"
                                    onClick={() => removeKnowledge(ind)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export default Knowledge