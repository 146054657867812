import { createSlice } from "@reduxjs/toolkit";

export const trainerSlice = createSlice({
  name: "trainer",
  initialState: {
    availability:[]
  },
  reducers: {
    setAvailability: (state, { payload }) => {
      state.availability = payload;
    },
  },
});

export const { setAvailability } = trainerSlice.actions;

export default trainerSlice.reducer;
