import React from "react";
import "./index.css";
import downArrow from "../../../assets/images/downArrow.png";
import Row from "react-bootstrap/Row";
import { previousStep } from "../../../redux/features/stepSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Loader from "../../loader/Loader";
import { skills } from "../../../constants/skills";
import { languages } from "../../../constants/language";
import { goals } from "../../../constants/goals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { addStudentBasicInfoAction } from "../../../redux/actions/studentAction";
import { nextStep } from "../../../redux/features/stepSlice";
import { toast } from "react-toastify";
import { Stack, FormControlLabel, Radio, Typography, Grid, Select, MenuItem, TextField, Button } from "@mui/material";

function SkillsStud() {
  const dispatch = useDispatch();
  const { basicInfo } = useSelector((state) => state.stepInfo);
  const { user } = useSelector((state) => state.userInfo);
  const [isLoading, setIsLoading] = useState("");
  const [localState, setLocalState] = useState({
    skillKnown: [],
    languageKnown: [],
    goals: goals.map((g) => {
      return {
        ...g,
        selected: false,
        text: "",
      };
    }),
    studentType: "student",
  });
  const previous = () => {
    dispatch(previousStep());
  };

  useEffect(() => {
    if (user) {
      let newGoals = goals.map((goal) => {
        var existing = user.profile.studentGoal || [];
        if (goal.value === "Others") {
          existing.forEach((item) => {
            if (item.includes("Others")) {
              goal.text = item.split("/")[1];
              goal.selected = false;
            }
          });
        } else {
          if (existing.indexOf(goal.value) >= 0) {
            goal.selected = true;
          } else {
            goal.selected = false;
          }
        }

        return goal;
      });

      setLocalState((prev) => ({
        ...prev,
        languageKnown: user?.profile?.languageKnown,
        skillKnown: user?.profile?.studentSkill,
        goals: newGoals,
        studentType: user?.profile?.studentType,
        studentExperience: user?.profile?.studentExperience,
      }));
    }
  }, [user]);

  const saveSkills = () => {
    let studentGoal = [];
    localState.goals.forEach((item) => {
      if (item?.selected) {
        if (item.updatedVal) {
          studentGoal.push(item.updatedVal);
        } else {
          studentGoal.push(item.value);
        }
      }
    });

    let skillsData = {
      studentGoal,
      languageKnown: localState.languageKnown,
      studentSkill: localState.skillKnown,
      studentType: localState.studentType,
    };
    if (localState.studentType == "experienced") {
      skillsData["studentExperience"] = localState.studentExperience;
    } else {
      skillsData["studentExperience"] = "0";
    }
    dispatch(addStudentBasicInfoAction(skillsData));
    dispatch(nextStep());
    toast.success("Profile Updated successfully ");
  };

  const changeStudentType = (e) => {
    setLocalState((prev) => ({ ...prev, studentType: e.target.value }));
  };

  const changeStudentExperience = (e) => {
    setLocalState((prev) => ({ ...prev, studentExperience: e.target.value }));
  };

  useEffect(() => {
    setIsLoading(basicInfo);
  }, [basicInfo]);

  // const onGoalChange = (g) => {
  //       setLocalState((prev) => ({
  //           ...prev,
  //           goals: goals.map((gs) => {
  //               if (gs.key === g.key) {
  //                   gs.selected = !!!gs.selected
  //               }
  //               return gs;
  //           })
  //       }))
  //   }

  const onGoalChange = (g) => {
    setLocalState((prev) => ({
      ...prev,
      goals: goals.map((gs) => {
        if (gs.key === g.key) {
          gs.selected = !!!gs.selected;
        }
        return gs;
      }),
    }));
  };

  const onGoalTextChange = (e, g) => {
    setLocalState((prev) => ({
      ...prev,
      goals: goals.map((gs) => {
        if (gs.key === g.key) {
          gs.text = e.target.value;
          let val = gs.value;
          let newVal = `${val}/${gs.text}`;
          gs.updatedVal = newVal;
        }
        return gs;
      }),
    }));
  };

  const updateUserInfo = (e) => {
    const key = e.target.name;
    if (key === "skillKnown" || key === "languageKnown") {
      let isPresent = localState[key].filter((item) => item === e.target.value);
      if (isPresent.length > 0) {
        return;
      }
      let newArray = [...localState[key], e.target.value];
      setLocalState((prev) => ({ ...prev, [key]: newArray }));
      return;
    } else {
      setLocalState((prev) => ({ ...prev, [key]: e.target.value }));
    }
  };

  const remove = (ind, type) => {
    let newType = (localState[type] || []).filter((lan, i) => i !== ind) || [];
    setLocalState((prev) => ({ ...prev, [type]: newType }));
  };

  return (
    <Stack className="skill_GoalsDiv">
      <Stack spacing={3}>
        <Stack>
          <Typography className="I_am">I am</Typography>
          <Stack direction="row">
            <Stack className="form-check form-check-inline">

              <FormControlLabel className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                checked={localState.studentType === "student" ? true : false}
                id="inlineRadio1"
                value="student"
                onChange={changeStudentType}
                control={<Radio />} label="Student"

              />

            </Stack>
            <Stack className="form-check form-check-inline">

              <FormControlLabel
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                checked={localState.studentType === "fresher" ? true : false}
                value="fresher"
                onChange={changeStudentType}
                control={<Radio />} label="Fresher"

              />

            </Stack>
            <Stack direction={'row'}>

              <FormControlLabel
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                checked={localState.studentType === "experienced" ? true : false}
                value="experienced"
                onChange={changeStudentType}
                control={<Radio />}
              />
              <Stack justifyContent={'center'}>
                <label >
                  <input
                    value={localState?.studentExperience}
                    className="year-input"
                    type="number"
                    onChange={changeStudentExperience}
                    disabled={localState.studentType !== "experienced"}
                  />
                  yrs Experience
                </label>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Grid container md={12}>
            <Grid item md={5}>
              <Stack className="form-group s-g-Div" spacing={1}>
                <Stack>
                  <label htmlFor="exampleInputPassword1">
                    Skills That I Am Master In{" "}
                  </label>
                </Stack>

                <Stack>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    className="form-control"
                    onChange={updateUserInfo}
                    name="skillKnown"
                    size='small'

                    style={{ width: '100%' }}
                  >
                    {skills.map((q) => {
                      return <MenuItem value={q.value}>{q.value}</MenuItem>;
                    })}
                  </Select>
                </Stack>
                {localState?.skillKnown?.length > 0 ? (
                  <Grid container mt={2} md={12} sm={12} className="lngKnownsCon" spacing={1}>
                    {localState.skillKnown.map((item, i) => (
                      <Grid item direction={'row'} color='white' alignItems={"center"} >
                        <Typography className="lngKnowns" >{item} <FontAwesomeIcon
                          icon={faXmark}
                          onClick={() => remove(i, "skillKnown")}
                        /></Typography>

                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={5}>
              <Stack className="form-group s-g-Div" spacing={1}>
                <Stack>
                  <label htmlFor="exampleInputPassword1">Language Known</label>
                </Stack>

                <Stack>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    className="form-control"
                    onChange={updateUserInfo}
                    name="languageKnown"
                    size='small'

                    style={{ width: '100%' }}
                  >
                    {languages.map((q) => {
                      return <MenuItem value={q.value}>{q.value}</MenuItem>;
                    })}
                  </Select>
                </Stack>

                {localState?.languageKnown?.length > 0 ? (
                  <Stack mt={2} className="lngKnownsCon" direction={'row'} spacing={2}>
                    {localState.languageKnown.map((item, i) => (
                      <Stack className="lngKnowns" color={'white'}>
                        <Typography className="lngKnowns">{item}
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => remove(i, "languageKnown")}
                          /></Typography>
                      </Stack>
                    ))}
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          <Typography className="goal">Goal</Typography>

          {localState.goals.map((g) => {
            return g.isOthers ? (
              <Stack className="d-inline-flex" direction={'row'} justifyContent='start' alignItems={'center'}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxNoLabel"
                  aria-label="..."
                  onChange={(e) => onGoalChange(g)}
                />
                <label className="form-check-label goalsCheck" htmlFor="inlineRadio1">
                  {g.value}
                </label>

                <TextField size="small"
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Type here"
                  value={g.text}
                  onChange={(e) => onGoalTextChange(e, g)}
                  disabled={!g.selected}
                />
              </Stack>
            ) : (
              <Stack direction={'row'}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="checkboxNoLabel"
                  value=""
                  checked={g.selected}
                  aria-label="..."
                  onChange={(e) => onGoalChange(g)}
                />
                <label className="form-check-label goalsCheck" htmlFor="inlineRadio1">
                  {g.value}
                </label>
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      <Stack direction={'row'} className="s-n-btn free_SN_btn" spacing={2} justifyContent='end'>
        <Stack>
          <button
            class="btn btn-outline prvs-btn"
            onClick={() => previous()}
            type="button"
          >
            Previous
          </button>
        </Stack>
        <Stack>
          <button
            class="btn s-n-button forHover"
            onClick={() => saveSkills()}
            type="button"
          >
            {isLoading ? <Loader color="white" loading={true} /> : "Save & Next"}
          </button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SkillsStud;
