import React, { useEffect, useRef, useState } from "react";
import search from "../../../assets/images/Search.png";
import trash from "../../../assets/images/trash.png";
import notes from "../../../assets/images/notes.png";
import fillArrow from "../../../assets/images/fillArrow.png";
import upload from "../../../assets/images/Upload.png";
import addicon from "../../../assets/images/Addicon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import GreenCheck from "../../../assets/images/greenCheck.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./index.css";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Stack } from "@mui/system";

function Client({ setForClient }) {
  // const [value, onChange] = useState(new Date());
  const [clientWorkFor, setClientWorkFor] = useState({
    clientName: "",
    fromDate: "",
    toDate: "",
    designation: "",
    artifacts: "",
  });

  const [allClients, setAllclients] = useState([]);

  const toRef = useRef(null);
  const fromRef = useRef(null);
  const fromUpdateRef = useRef(null);
  const toUpdateRef = useRef(null);


  const { user } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (user && user.skill) {
      let oldSkills = user?.skill?.client;

      if (oldSkills) {
        oldSkills = oldSkills.map((it) => {
          let newFromDate = getFormattedDate(it.fromDate.split("T")[0])
          let newToDate = getFormattedDate(it.toDate.split("T")[0])
          let newIt = {
            ...it,
            fromDate: newFromDate,
            toDate: newToDate
          }
          return newIt
        })
        setAllclients(oldSkills);
      }
    }
  }, [user]);

  useEffect(() => {
    setForClient(allClients);
  }, [allClients]);

  const showDatePicker = () => {
    fromRef.current.showPicker();
  };

  const showToDatePicker = () => {
    toRef.current.showPicker();
  };

  const showFromUpDatePicker = () => {
    fromUpdateRef.current.showPicker();
  };

  const showToUpDatePicker = () => {
    toUpdateRef.current.showPicker();
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);

    return date.toLocaleString("en-US", { month: "short" });
  };

  const getFormattedDate = (currDate) => {
    let monthNum = new Date(currDate).getMonth();
    let month = getMonthName(monthNum);
    let date = currDate.split("-");
    let newDate = `${date[2]}-${month}-${date[0]}`;
    return newDate;
  };

  const changeFromDate = (e) => {
    let formattedDate = getFormattedDate(e.target.value);
    setClientWorkFor((prev) => ({ ...prev, fromDate: formattedDate }));
  };

  const changeToDate = (e) => {
    let formattedDate = getFormattedDate(e.target.value);
    setClientWorkFor((prev) => ({ ...prev, toDate: formattedDate }));
  };

  const singleFromDateUpdate = (e, ind) => {
    let formattedDate = getFormattedDate(e.target.value);
    let newClients = allClients.map((it, i) => {
      if (i === ind) {
        it.fromDate = formattedDate;
      }
      return it;
    });

    setAllclients(newClients);
  };

  const singleToDateUpdate = (e, ind) => {
    let formattedDate = getFormattedDate(e.target.value);
    let newClients = allClients.map((it, i) => {
      if (i === ind) {
        it.toDate = formattedDate;
      }
      return it;
    });

    setAllclients(newClients);
  };

  const addNew = () => {
    if (
      !clientWorkFor?.clientName ||
      !clientWorkFor?.fromDate ||
      !clientWorkFor?.toDate ||
      !clientWorkFor?.designation
    ) {
      return toast.error("Enter all the details!");
    }
    setAllclients((prev) => [...prev, clientWorkFor]);
    setClientWorkFor({
      clientName: "",
      fromDate: "",
      toDate: "",
      designation: "",
      artifacts: "",
    });
  };

  const removeClient = (ind) => {
    setAllclients(allClients.filter((cl, i) => i !== ind));
  };

  const editClient = (ind, isEdit) => {
    let newClients = allClients.map((item, i) => {
      let newIt = { ...item };
      if (i === ind) {
        newIt.isEditing = isEdit;
      }
      return newIt;
    });
    setAllclients(newClients);
  };

  const updateOldClient = (e, index) => {
    const key = e.target.name;
    let val = e.target.value;
    let newClient = allClients.map((it, ind) => {
      if (ind === index) {
        it[key] = val;
      }
      return it;
    });
    setAllclients(newClient);
  };

  return (
    <>
      <Typography className="table-lable-head">Clients worked for</Typography>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Client Name</TableCell>
            <TableCell align="left">Completed</TableCell>
            <TableCell align="left">To</TableCell>
            <TableCell align="left">Designation </TableCell>
            <TableCell align="left">Artifacts</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" component="th" scope="row">
              <div className="clientName">
                <input
                  type="text"
                  style={{ maxWidth: "157px" }}
                  class="form-control forBorder skillInput"
                  id="exampleInputPassword1"
                  placeholder="Name"
                  onChange={(e) =>
                    setClientWorkFor((prev) => ({
                      ...prev,
                      clientName: e.target.value,
                    }))
                  }
                  value={clientWorkFor?.clientName}
                  name="area"
                />
              </div>
            </TableCell>
            <TableCell align="left">
              <Stack className="completed" direction={'row'}>
                <Stack>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ marginRight: "8px", color: "#2E00FF" }}
                  />
                </Stack>
                <Stack>
                  <input
                    type="date"
                    style={{ border: "none", padding: "0.5rem" }}
                    onChange={(e) => changeFromDate(e)}
                    // value={clientWorkFor?.fromDate}
                    ref={fromRef}
                    onClick={showDatePicker}
                  />
                </Stack>
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Stack className="completed" direction={'row'}>
                <Stack>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ marginRight: "8px", color: "#2E00FF" }}
                  />
                </Stack>

                <Stack>
                  <input
                    type="date"
                    style={{ border: "none", padding: "0.5rem" }}
                    onChange={(e) => changeToDate(e)}
                    // value={clientWorkFor?.toDate}
                    ref={toRef}
                    onClick={showToDatePicker}
                  />
                </Stack>
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Stack className='designation'>
                <input
                  type="text"
                  style={{ maxWidth: "100px" }}
                  class="form-control forBorder skillInput"
                  id="exampleInputPassword1"
                  placeholder="Designation"
                  onChange={(e) =>
                    setClientWorkFor((prev) => ({
                      ...prev,
                      designation: e.target.value,
                    }))
                  }
                  value={clientWorkFor?.designation}
                  name="area"
                />
              </Stack>
            </TableCell>
            <TableCell align="left">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={upload} className="uploadImg" style={{ width: '15px' }} alt="upload Icon" />
              </div>
            </TableCell>
            <TableCell align="left">
              <img
                src={addicon}
                className="blueCirImg"
                alt="add Icon"
                onClick={addNew}
              />
            </TableCell>
          </TableRow>

          {allClients.map((item, i) => (
            <TableRow className="arrow">
              {item.isEditing ? (
                <TableCell>
                  <div className="areaSearch">
                    <input
                      type="number"
                      class="form-control forBorder skillInput"
                      id="exampleInputPassword1"
                      placeholder="Name of client"
                      name="clientName"
                      value={item.clientName}
                      onChange={(e) => updateOldClient(e, i)}
                    />
                  </div>
                </TableCell>
              ) : (
                <TableCell>{item.clientName}</TableCell>
              )}
              {item.isEditing ? (
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ marginRight: "8px", color: "#2E00FF" }}
                    />

                    <input
                      type="date"
                      style={{ border: "none", padding: "0.5rem" }}
                      onChange={(e) => singleFromDateUpdate(e, i)}
                      ref={fromUpdateRef}
                      onClick={showFromUpDatePicker}
                    />
                  </div>
                </TableCell>
              ) : (
                <TableCell>{item.fromDate} </TableCell>
              )}

              {item.isEditing ? (
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ marginRight: "8px", color: "#2E00FF" }}
                    />
                    <input
                      type="date"
                      style={{ border: "none", padding: "0.5rem" }}
                      onChange={(e) => singleToDateUpdate(e, i)}
                      ref={toUpdateRef}
                      onClick={showToUpDatePicker}
                    />
                  </div>
                </TableCell>
              ) : (
                <TableCell>{item.toDate} </TableCell>
              )}
              {item.isEditing ? (
                <TableCell>
                  <div className="areaSearch">
                    <input
                     type="number"
                     class="form-control forBorder skillInput"
                      id="exampleInputPassword1"
                      placeholder="Designation"
                      value={item.designation}
                      onChange={(e) => updateOldClient(e, i)}
                      name="designation"
                    />
                  </div>
                  {/* <span>
                  <img src={downArrow} alt="down Arrow" />
                </span> */}
                </TableCell>
              ) : (
                <TableCell>{item.designation}</TableCell>
              )}

              <TableCell></TableCell>
              <TableCell >
                {item.isEditing ? (
                  <img
                    src={GreenCheck}
                    alt="green check"
                    onClick={() => editClient(i, false)}
                  />
                ) : (
                  <img
                    src={notes}
                    alt="note"
                    onClick={() => editClient(i, true)}
                  />
                )}

                <img
                  src={trash}
                  alt="trashIcon"
                  onClick={() => removeClient(i)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default Client;
