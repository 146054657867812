import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import stepReducer from "./features/stepSlice";
import studentReducer from "./features/studentSlice"
import trainerSlice from "./features/trainerSlice";


export const store = configureStore({
  reducer: {
    userInfo: userReducer,
    stepInfo: stepReducer,
    studentInfo: studentReducer,
    trainerInfo: trainerSlice
  },
});