import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-quill/dist/quill.snow.css";
import tips from "../../../assets/images/tipsImg.png";
import { useState } from "react";
import { addProfSummAction } from "../../../redux/actions/trainerAction";
import { useDispatch, useSelector } from "react-redux";
import { previousStep } from "../../../redux/features/stepSlice";
import Loader from "../../loader/Loader";
import MUIRichTextEditor from "mui-rte";
import trash from "../../../assets/images/trash.png";
import "./index.css";
import { Grid, Stack, Typography } from "@mui/material";


const save = (data) => {
    console.log(data);
};
const myTheme = createTheme({
});


function ProfessionalSummary() {
    const dispatch = useDispatch()

    // let highExpRef = useRef()

    const { user } = useSelector((state) => state.userInfo);
    const { professional } = useSelector((state) => state.stepInfo);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user) {

            if (user?.profile?.professionalSummary) {

                summaryRef.value = user?.profile?.professionalSummary;
            }

            if (user?.profile?.experienceHighlight) {
                expRef.value = user?.profile?.experienceHighlight;

            }
        }
    }, [user]);

    useEffect(() => {
        setIsLoading(professional);
    }, [professional]);



    const previous = () => {
        dispatch(previousStep())
    }

    const saveSummary = () => {
        dispatch(
            addProfSummAction({
                professionalSummary: summaryRef.value,
                experienceHighlight: expRef.value,
            })
        );

    }

    let summaryRef;
    let expRef;




    return (
        <Stack container md={12} className='professionalSummary' spacing={3}>
            <Stack>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'} >
                    <Typography className='professionalTxt'>Professional Summary About Me</Typography>
                    <Stack className="left-side-chat">
                        <Stack direction={'row'}>
                            <img src={tips} alt="tips" />
                            <Typography className="effective">An effective summary follow this formula</Typography>
                        </Stack>
                        <Typography>
                            Professional Title (if relevant) + Key Experiences (with the total
                            number of years worked) + Top Skills/Expertise/Unique Values
                        </Typography>
                    </Stack>
                </Stack>
                <Stack className="right-side-chat ProfessionalEditor" >
                    <ThemeProvider theme={myTheme} >
                        <MUIRichTextEditor
                            className='TextEditor'
                            label="Type something here..."
                            onSave={save}
                            inlineToolbar={true}
                        />
                    </ThemeProvider>

                </Stack>
            </Stack>
            <Stack>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
                    <Grid container md={12} style={{ display: 'flex', flexDirection: 'row' }} justifyContent='space-between' alignItems={'end'}>
                        <Grid item md={4} xs={12}>
                            <Typography className="professionalTxt">Highlight of the experience</Typography>
                        </Grid>
                        <Grid item md={8} xs={12} className='Commenttwo'>
                            <Stack className="left-side-chat scndCmnt">
                                <Stack direction={'row'}>
                                    <img src={tips} alt="tips" />
                                    <Typography className="effective"> What should go here ?</Typography>
                                </Stack>
                                <Typography>
                                    Add Top Achievements (preferably measurable results) , that are short and clearAdd Top Works that can bring in attention.Mention any Client names that you want to highlight in BOLD Mention any Client names that you want to highlight in BOLD, Mention any Client names that you want to highlight in BOLD.
                                    Add Top Achievements (preferably measurable results) , that are short and clear
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack className="right-side-chat ProfessionalEditor">

                    <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                            label="Type something here..."
                            onSave={save}
                            inlineToolbar={true}
                        />
                    </ThemeProvider>

                </Stack>
            </Stack>
            <Stack md={12}>
                <Stack md={6}>
                </Stack>
                <Stack md={6} justifyContent={'end'} className="s-n-btn free_SN_btn" direction={'row'}>
                    <Stack>
                        <button
                            class="btn btn-outline prvs-btn"
                            onClick={() => previous()}
                            type="button"
                        >
                            Previous
                        </button>
                    </Stack>
                    <Stack>
                        <button
                            class="btn s-n-button forHover"
                            onClick={() => saveSummary()}
                            type="button"
                        >
                            {isLoading ? <Loader color="white" loading={true} /> : "Save & Next"}
                        </button>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default ProfessionalSummary;
