import axios from "axios";

let token = localStorage.getItem("accessToken");

const instance = axios.create({
  baseURL: "https://zencoachersapi.algofolks.com",
  // baseURL:"http://localhost:3001"
});

instance.interceptors.request.use((req) => {
  
  token = localStorage.getItem("accessToken");

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

export default instance;
