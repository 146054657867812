import { createSlice } from "@reduxjs/toolkit";

export const stepSlice = createSlice({
  name: "step",
  initialState: {
    basicInfo: false,
    professional: false,
    skills: false,
    availability: false,
    community: false,
    profileStat: false,
    currentStep: 0,
    profileCover:false,
  },
  reducers: {
    loadingStep: (state, { payload }) => {
      state[payload.step] = payload.state;
    },
    setStep: (state, { payload }) => {
      state.currentStep = payload;
    },
    nextStep: (state, { payload }) => {
      state.currentStep = state.currentStep + 1;
    },
    previousStep: (state, { payload }) => {
      state.currentStep = state.currentStep - 1;
    },
  },
});

export const { loadingStep, setStep, nextStep, previousStep } = stepSlice.actions;

export const selectStep = (state) => state.stepInfo.step;

export default stepSlice.reducer;
