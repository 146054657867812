import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loading: false,
    error: null,
    loadUserLoading: false,
    isAuthenticated: false,
    profileStats:{}
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload.user;
    },
    setToken: (state, { payload }) => {
      state.isAuthenticated = payload.authenticated;
      localStorage.setItem("accessToken", payload.token);
    },
    loading: (state, { payload }) => {
      state.loading = payload;
    },

    setProfileStats:(state,{payload})=>{
      state.profileStats = payload
    }
  },
});

export const { setUser, setToken, loading, setProfileStats } = userSlice.actions;

// selectors
export const selectUser = (state) => state.userInfo.user;

export default userSlice.reducer;
