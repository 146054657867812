
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { loading, isAuthenticated } = useSelector((state) => state.userInfo);
    const navigate = useNavigate()
    return (
        <>
            {loading === false && (isAuthenticated ? <Outlet /> : navigate("/")
            )}
        </>
    );
};

export default ProtectedRoute;
