import React, { useEffect, useRef, useState } from "react";
import ProfileStats from "../../components/profile/profileStats/ProfileStats";
import ProfileTabs from "../../components/profile/profileTabs/ProfileTabs";
import simple from '../../assets/images/simple.png';
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/actions/userAction";
import camera from '../../assets/images/camera.png';
import { toast } from "react-toastify";
import { uploadCoverImgAction } from "../../redux/actions/trainerAction";
import Loader from "../../components/loader/Loader";
import { Container, Grid, Stack, Typography } from "@mui/material";
import './index.css';

function Profile() {

    const [coverImage, setCoverImage] = useState("");
    const { user } = useSelector((state) => state.userInfo);
    const [isLoading, setIsLoading] = useState(false)
    const { profileCover } = useSelector((state) => state.stepInfo);
    const dispatch = useDispatch()

    const uploadref = useRef(null);


    useEffect(() => {
        if (user) {
            console.log(user?.profile?.coverImage, "123")
            setCoverImage(user?.profile?.coverImage);
        }
    }, [user]);

    useEffect(() => {
        setIsLoading(profileCover);
    }, [profileCover]);

    useEffect(() => {
        dispatch(getProfile());

    }, [])

    const handleClick = () => {
        uploadref.current.click();
    };

    const fileHandler = (e) => {
        const fd = new FormData();
        let file = e.target.files[0];
        fd.append("file", file);

        dispatch(
            uploadCoverImgAction(fd, () => {
                toast.success("Image uploaded successfully!");
            })
        );
    };




    return (

        <Container style={{ maxWidth: '1440px' }} >
            <Stack className="position-relative" mt={3}>
                <Stack className="update-head">
                    <Typography className="update-head-name">Home</Typography>
                </Stack>
                <Stack className="update-cover">
                    {isLoading ? (
                        <Loader color="#2E00FF" loading={true} size={70} />
                    ) : (
                        <Stack direction={'row'} justifyContent='end'>
                            {/* <img src={coverImage ? cover/Image : simple} alt="simple" /> */}
                            <Stack>
                                <img src="https://www.zencoachers.com/static/media/simple.41dcbbd6e72a83cf529e.png" alt="as" />
                            </Stack>
                            <Stack className="backgroundUpload" direction={'row'} >
                                <Stack>
                                    <img
                                        src={camera}

                                        onClick={handleClick}
                                    />
                                </Stack>
                                <Stack className="updateCover">
                                    Update Cover
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <Stack className="information" mt={-14}>
                <Grid container md={12}>
                    <Grid className="profileStats" item md={4} xs={12}>
                        <ProfileStats />
                    </Grid>
                    <Grid item md={8} xs={12} className="profileTabs">
                        <ProfileTabs />
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
}

export default Profile;
