import React from "react";
import "react-range-slider-input/dist/style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Map from "../../map/Map"
import nomap from "../../../assets/images/nomap.png"
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addBasicInfoAction, sendOTP, verifyOTP
} from "../../../redux/actions/trainerAction";
import Loader from "../../loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "../../../axios"
import Slider from '@mui/material/Slider';
import { nextStep } from "../../../redux/features/stepSlice";
import { Button, Container, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Divider from '@mui/material/Divider';




function BasicInfo() {
  const dispatch = useDispatch();

  const [localState, setLocalState] = useState({
    displayName: "",
    profileType: "Trainer",
    displayImage: "",
    email: "",
    mobile: "",
    role: "",
    languageKnown: [],
    area: {},
    timezone: "",
    travelDistanceMax: "",
  });

  //  const [address, setaddress] = useState("");
  const [locality, setlocality] = useState("");
  const [, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [isMobileVerifying, setIsMobileVerifying] = useState(false);
  const { user } = useSelector((state) => state.userInfo);
  const { basicInfo } = useSelector((state) => state.stepInfo);
  const [isLoading, setIsLoading] = useState("");
  const [otp, setOTP] = useState("");

  useEffect(() => {
    if (user) {
      setLocalState((prev) => ({
        ...prev,
        ...user.profile
      }));
      storeProfileStats();
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(basicInfo);
  }, [basicInfo]);


  const storeProfileStats = () => { };

  const updateUserInfo = (e) => {
    const key = e.target.name;
    if (key === "languageKnown") {
      let isPresent = localState[key].filter(item => item === e.target.value)

      if (isPresent.length > 0) {
        return
      }
      let newLanguages = [...localState[key], e.target.value];
      setLocalState((prev) => ({ ...prev, [key]: newLanguages }));
      return;
    }
    setLocalState((prev) => ({ ...prev, [key]: e.target.value }));
  };

  console.log("localState", localState);

  const handlePickLocationInput = (address) => {
    setlocality(address);
  };
  const handleSelect = async (address) => {
    setcity("");
    setstate("");
    setcountry("");
    setlocality("");

    console.log("adddd", address)

    let results = await geocodeByAddress(address)

    if (results.length) {
      let { lat, lng } = await getLatLng(results[0]);
      let { data } = await axios.post("/timezone", { lat: lat, long: lng });

      let add = results[0].formatted_address;
      setlocality(add)

      let pincode =
        results[0].address_components[results[0].address_components.length - 1]
          .long_name;
      let country =
        results[0].address_components[results[0].address_components.length - 2]
          .long_name;
      let city =
        results[0].address_components[results[0].address_components.length - 4]
          .long_name;

      setLocalState((prev) => ({
        ...prev,
        area: { lat: lat, long: lng },
        pincode,
        country,
        city,
        timezone: data.timeZoneId,
      }));

    } else {
      toast.error("Try again!!");
    }



  };

  const removeLang = (ind) => {

    let newLangs = localState.languageKnown.filter((lan, i) => i !== ind)

    setLocalState(prev => ({ ...prev, languageKnown: newLangs }))

  }

  const getPhone = (val) => {
    setLocalState(prev => ({ ...prev, mobile: val }))

  }

  const saveBasicInfo = () => {
    dispatch(addBasicInfoAction(localState, () => {
      toast.success("Profile Updated successfully!")
    }));
    dispatch(nextStep());
  };

  const sendMobileOTP = () => {
    toast.success("OTP has been sent to your mobile no!")
    dispatch(sendOTP(localState, () => {
    }));
  }

  const verifyMobile = () => {

    let regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    if (localState.mobile == null) {
      return toast.error("Enter valid Mobile Number");
    }

    let newMobile = "+" + localState.mobile;
    let isValid = regex.test(newMobile);
    if (!isValid) {
      return toast.error("Enter valid Mobile Number");
    }
    dispatch(addBasicInfoAction(localState));
    dispatch(sendOTP({ "mobileNumber": localState.mobile }, () => {
      toast.success("OTP has been sent to your mobile no!")
    }));
    setIsMobileVerifying(true)
  }

  const onVerification = () => {
    dispatch(verifyOTP({ "otp": otp }, (msg) => {
      toast.success(msg);
    }));
    setIsMobileVerifying(false)
  }

  return (
    <Container>
      <Grid container md={12} style={{ padding: "22px 0px" }}>
        <Grid item md={5} xs={12} className="">
          <Stack spacing={2}>
            <Stack class="form-group forTopMargin" spacing={1}>
              <Typography htmlFor="exampleInputPassword1">Full Name</Typography>
              <TextField size="small"
                type="text"
                width='100%'
                class="form-control"
                id="exampleInputPassword1"
                name="displayName"
                placeholder="name"
                value={localState?.displayName}
                onChange={updateUserInfo}
              />
            </Stack>
            <Stack class="form-group" spacing={1}>
              <Typography htmlFor="exampleInputEmail1">Email</Typography>
              <TextField size="small"
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={localState?.email}
                name="email"
                onChange={updateUserInfo}

                disabled
              />
              <small id="emailHelp" class="form-text text-muted Typography-Text">
                To change email ID, Signout and Signin with the new ID
              </small>
            </Stack>

            <Stack class="form-group" spacing={1}>
              <Typography htmlFor="exampleInputPassword1">Language Known</Typography>
              <Select
                onChange={updateUserInfo}
                name="languageKnown"
                size='small'
                style={{ width: '100%' }}
              >
                <MenuItem value="Hindi">Hindi</MenuItem >
                <MenuItem value="English">English</MenuItem >
                <MenuItem value="Spanish">Spanish</MenuItem >
              </Select>


            </Stack>
            {localState.languageKnown.length > 0 ? (
              <Stack className="lngKnownsCon" direction={'row'}>
                {localState.languageKnown.map((item, i) => (
                  <Stack className="lngKnowns" direction={'row'}>
                    <Stack>{item}</Stack>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="crossIcon"
                      onClick={() => removeLang(i)}
                    />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <></>
            )}
            <Stack spacing={1}>
              <Typography >My Role</Typography>
              <TextField size="small"

                type="text"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Web Designer"
                name="role"
                value={localState?.role}
                onChange={updateUserInfo}
              />
            </Stack>
            {!isMobileVerifying ? (
              <Stack class="form-group" spacing={1}>
                <Grid container md={12} className="phone-row" direction={'row'}>
                  <Grid item md={9} sx={12}>
                    <Typography>Phone Number</Typography>
                    <PhoneInput
                      country={"in"}
                      value={localState.mobile}
                      onChange={getPhone}
                    // inputStyle={{ width: "100%" }}
                    />
                  </Grid>

                  <Grid item md={3} sx={12} justifyContent={'end'} alignItems='center' className="verify-btn">
                    <button
                      class="btn forHover"
                      type="button"
                      onClick={(e) => verifyMobile()}
                    >
                      Verify
                    </button>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <Stack class="form-group">
                <Stack>
                  <Stack item>
                    <Typography htmlFor="inputEmail4">Phone Number</Typography>
                    <div className="otp-text">
                      Verification code has been sent to {localState.mobile}
                    </div>
                  </Stack>
                </Stack>
                <Stack className="phone-row" direction={'row'}>
                  <Stack item md={8}>
                    <TextField size="small"
                      type="number"
                      class="form-control"
                      id="exampleInputPassword1"
                      name="OTP"
                      placeholder="OTP"
                      value={otp}
                      max="999999"
                      onChange={(e) => setOTP(e.target.value)}
                    />

                  </Stack>
                  <Grid item md={3} sx={12} justifyContent={'end'} alignItems='center' className="verify-btn">
                    <button
                      class="btn forHover"
                      type="button"
                      onClick={(e) => onVerification()}
                    >
                      Verify
                    </button>
                  </Grid>
                </Stack>
                {/* <Stack className="phone-row"> */}
                <Stack className="otp-verify" direction={'row'} justifyContent={'space-between'} >
                  <Stack onClick={(e) => sendMobileOTP()}>Resend Code</Stack>
                  <Stack onClick={(e) => setIsMobileVerifying(false)}>Edit Number</Stack>
                </Stack>
                {/* </Stack> */}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item md={2} xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Divider orientation="vertical" flexItem style={{ height: "100%" }} />
        </Grid>
        <Grid item md={5} xs={12} className="" >
          <Stack spacing={3}>
            <Stack class="form-group forTopMargin" spacing={1}>
              <Typography htmlFor="exampleInputPassword1">Area</Typography>
              <Stack className="areaSearch">

                <PlacesAutocomplete
                  value={locality}
                  onChange={handlePickLocationInput}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <Stack>
                      <TextField size="small"
                        {...getInputProps({
                          placeholder: "Search here",
                          className: "form-control",
                        })}
                      />

                      <Stack className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <Stack
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  )}
                </PlacesAutocomplete>
              </Stack>
            </Stack>
            <Stack className="mapping" spacing={1}>
              <Typography htmlFor="exampleInputPassword1">
                {localState.city}{" "}
                <span>
                  {localState.pincode}
                  {localState.country}
                </span>
              </Typography>

              {!(localState.area.lat && localState.area.long) ? (
                <Stack className="nomapCont">
                  <Stack style={{ height: "30%", width: "80%" }}>
                    <img
                      src={nomap}
                      style={{ width: "100%", height: "100%" }}
                      alt="no map"
                    />
                  </Stack>
                </Stack>
              ) : (
                <Map lat={localState.area.lat} lng={localState.area.long} />
              )}
            </Stack>
            <Stack class="form-group" spacing={1}>
              <Typography htmlFor="exampleInputPassword1">Time Zone</Typography>
              <TextField size="small"
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="IST - Indian Standard Timezone"
                name="timezone"
                disabled={true}
                value={localState?.timezone}
                onChange={updateUserInfo}
              />

            </Stack>
            <Stack class="form-group" spacing={1}>
              <Typography htmlFor="customRange1" class="form-Typography travel-dis">
                Travel Distance({localState?.travelDistanceMax}km){" "}
                <span>25km</span>
              </Typography>

              <Slider defaultValue={50} aria-label="Default"
                type="range"
                class="slider"
                id="customRange1"
                name="travelDistanceMax"
                max="25"
                value={localState?.travelDistanceMax}
                onChange={updateUserInfo}
              />

            </Stack>
          </Stack>
        </Grid>


      </Grid>
      <Grid container md={12}>
        <Grid item md={7} xs={12}>

        </Grid>
        <Grid item md={5} xs={12}>
          <Stack className="s-n-btn" alignItems='end'>
            <button
              class="btn s-n-button forHover"
              type="button"
              onClick={() => saveBasicInfo()}
            >
              {isLoading ? (
                <Loader color="white" loading={true} />
              ) : (
                "Save & Next"
              )}
            </button>
          </Stack>
        </Grid>

      </Grid>

    </Container>
  );
}

export default BasicInfo;
