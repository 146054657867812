import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faMessage, faBars } from '@fortawesome/free-solid-svg-icons'
import downArrow from '../../../assets/images/downArrow.png'
import { useDispatch } from 'react-redux'
import { setToken } from '../../../redux/features/userSlice'
import { Container, Typography, Stack, Paper, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import './index.css'

const AppHeader = () => {
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch(setToken({ token: null, authenticated: false }));
  }
  return (
    <Container style={{ maxWidth: '1400px' }}>
      <Stack mt={3} direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Stack className='headerCircle'></Stack>
          <Stack className="headerLogo" direction={'row'}>
            <Typography className='headerPro'>Pro</Typography>
            <Typography className='headerTrainer'> trainer </Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={3}>
          <Stack className="protrainer-R" direction={'row'} spacing={2}>
            <Stack direction={'row'}>
              <Paper>
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  placeholder="Search"
                />
              </Paper>
            </Stack>
            <Stack direction={'row'} className='notification' spacing={2}>
              <FontAwesomeIcon icon={faBell} />
              <FontAwesomeIcon icon={faMessage} />
              <Typography className="downArrowIMG">
                Admin
                <img src={downArrow} alt="downArrowImg" />
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} className='logout'>
            <FontAwesomeIcon icon={faBars} className="bars" />
            <Typography className="menu cursor-pointer" onClick={() => logOut()}>
              Log Out
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}

export default AppHeader