import React, { useEffect, useState } from 'react'
import greendot from '../../../assets/images/greenDot.png';
import camera from '../../../assets/images/camera.png';
import person from '../../../assets/images/person.png';
import LinearProgress from '@mui/material/LinearProgress';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadProfileImgAction } from '../../../redux/actions/trainerAction';
import Loader from '../../loader/Loader';
import { toast } from 'react-toastify';
import 'react-circular-progressbar/dist/styles.css';
import "./index.css"
import { Container, Typography } from '@mui/material';
import { Stack } from '@mui/system';


function ProfileStats() {
  const uploadref = useRef(null)
  const [profile, setProfile] = useState({})
  const [stats, setStats] = useState({})
  const dispatch = useDispatch()
  const handleClick = () => {
    uploadref.current.click()
  }
  const { user } = useSelector((state) => state.userInfo);
  const { profileStat } = useSelector((state) => state.stepInfo);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (user) {
      setProfile(user.profile);
      setStats(user.stats);
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(profileStat)
  }, [profileStat]);

  const fileHandler = (e) => {

    const fd = new FormData();
    let file = e.target.files[0]
    fd.append('file', file)

    dispatch(uploadProfileImgAction(fd, () => {
      toast.success("Image uploaded successfully!")
    }))
  }

  return (
    <Container style={{ maxWidth: '1440px' }}>
      <Stack className="user-info-left" spacing={2}>
        <Stack className="user_profile">
          <Stack className="ProFile">
            {isLoading ? (
              <Stack
                style={{

                  border: "1px solid lightgray",
                  padding: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Loader color="#2E00FF" loading={true} size={50} />
              </Stack>
            ) : profile?.displayImage ? (
              <Stack
                style={{
                  padding: "0px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <img
                  src={profile?.displayImage}
                  style={{
                    borderRadius: "50%",
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                  alt="person"
                />{" "}
              </Stack>
            ) : (
              <Stack className="UserProFile" style={{}}>
                <img src={person} alt="person" />
              </Stack>
            )}
          </Stack>
          <img src={greendot} className="greendot" alt="green Dot" />

          <img
            src={camera}
            className="camera"
            onClick={handleClick}
            alt="camera"
          />

          <input
            type="file"
            ref={uploadref}
            onChange={fileHandler}
            style={{ display: "none" }}
          />
        </Stack>
        <Stack className='displayName'>
          <Typography className=" profileStateName">{profile?.displayName}</Typography>
        </Stack>
        {/* <Typography className="user-designation text-center">{profile?.role}</Typography> */}
        <Stack className="user-score" direction={'row'}>
          <Typography className='overallScore'>Overall Score</Typography>
          <Stack className="circularProgress">
            <CircularProgressbar
              value={stats?.overall}
              text={`${stats?.overall}%`}
              styles={buildStyles({
                pathColor: "#2E00FF",
                textColor: "#06021C",
                trailColor: "#d6d6d6",
                textSize: "26px",
                backgroundColor: "#3e98c7",
              })}
            />
          </Stack>
        </Stack>
  

        <Stack className="basic-information" spacing={1} >
          <label className="form-label">
            Basic Information <Typography>{stats?.basic}%</Typography>
          </label>
          <LinearProgress value={stats?.basic} variant="determinate" />
        </Stack>
        <Stack className="basic-information" spacing={1}>
          <label htmlFor="customRange1" className="form-label">
            Professional Summary <Typography>{stats?.professional}% </Typography>
          </label>
          <LinearProgress value={stats?.professional} variant="determinate" />
        </Stack>
        <Stack className="basic-information" spacing={1}>
          <label htmlFor="customRange1" className="form-label">
            Skill & Experience <Typography>{stats?.skill}% </Typography>
          </label>
          <LinearProgress value={stats?.skill} variant="determinate" />
        </Stack>
        <Stack className="basic-information" spacing={1}>
          <label htmlFor="customRange1" className="form-label">
            My Availability <Typography>{stats?.available}% </Typography>
          </label>
          <LinearProgress value={stats?.available} variant="determinate" />
        </Stack>
        <Stack className="basic-information" spacing={1}>
          <label htmlFor="customRange1" className="form-label">
            Community engagement<Typography>{stats?.community}% </Typography>
          </label>
          <LinearProgress value={stats?.community} variant="determinate" />
        </Stack>
      </Stack>
    </Container>
  );
}

export default ProfileStats