import React from 'react'
import { SocialIcon } from 'react-social-icons';
import playstore from '../../../assets/images/google_play.png';
import apple from '../../../assets/images/apple.png';
import './index.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import bluedot from '../../../assets/images/blueDot.png';
import { Button } from 'react-bootstrap';
import { Container } from '@mui/system';
import { Stack, Grid, Typography } from '@mui/material';


const AppFooter = () => {
    return (
        <Stack mt={10}>
            <footer style={{ background: "#0B0140", color: 'white' }} >
                <Container style={{ maxWidth: '1440px' }} >
                    <Grid container md={12} padding={'100px  0px 0px 50px'}>
                        <Grid md={5} className='footer_row' >
                            <Stack className="footer-para" spacing={5}>
                                <Stack>
                                    <Stack direction={'row'}>
                                        <Stack className='logomark'></Stack>
                                        <Typography className='footerlogo footerPro'>Pro</Typography>
                                        <Typography className='footerlogo'>trainer</Typography>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <Typography>Rem ipsum dolor sit amet consectetur.</Typography>
                                    <Typography> Ac cumsan risus facilisi maurF</Typography>
                                </Stack>
                                <Stack>
                                    <Typography>Jorem ipsum dolor sit amet</Typography>
                                    <Typography>consectetur. atibus quis neque.Lorem ipsum dolor </Typography>
                                    <Typography>sit sectetur. Pen atibus quis neque.</Typography>
                                </Stack>
                                <Stack md={12} direction='row' spacing={3}>
                                    <Stack direction={'row'} spacing={3} className='apple'>
                                        <Stack justifyContent={'center'} alignItems='center'>
                                            <img width={'100%'} src={apple} />
                                        </Stack>
                                        <Stack >
                                            <Typography color={'#97A8BF'} fontWeight='400'>Download on the</Typography>
                                            <Typography fontWeight={'700'} fontSize='22px'>App Store</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={'row'} spacing={3} className='playsotre' >
                                        <Stack justifyContent={'center'} alignItems='center'>
                                            <img src={playstore} />
                                        </Stack>
                                        <Stack>
                                            <Typography color={'#97A8BF'} fontWeight='400'>Get in on</Typography>
                                            <Typography fontWeight={'700'} fontSize='22px'>Google Play</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid md={7}>
                            <Stack width='100%' direction={'row'} justifyContent='space-between'>
                                <Stack>
                                    <Grid md={1}>
                                        <Stack spacing={3}>
                                            <Stack className='footertabname'>Service</Stack>
                                            <Stack spacing={2}>
                                                <Typography className='footertablist'>Home</Typography>
                                                <Typography className='footertablist'>About Me</Typography>
                                                <Typography className='footertablist'>Work</Typography>
                                                <Typography className='footertablist'>Achivement</Typography>
                                                <Typography className='footertablist'>Contact</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Stack>
                                <Stack>
                                    <Grid md={2}>
                                        <Stack spacing={3}>
                                            <Stack className='footertabname'>About Us</Stack>
                                            <Stack spacing={2}>
                                                <Typography className='footertablist'>Terms & Conditions</Typography>
                                                <Typography className='footertablist'>Copyrights</Typography>
                                                <Typography className='footertablist'>FUP</Typography>
                                                <Typography className='footertablist'>VAT</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Stack>
                                <Stack>
                                    <Grid md={1}>
                                        <Stack spacing={3}>
                                            <Stack className='footertabname'>Company</Stack>
                                            <Stack spacing={2}>
                                                <Typography className='footertablist'>Blog</Typography>
                                                <Typography className='footertablist'>Customers</Typography>
                                                <Typography className='footertablist'>About Us</Typography>
                                                <Typography className='footertablist'>Career</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Stack>
                                <Stack>
                                    <Grid md={1}>
                                        <Stack spacing={3}>
                                            <Stack className='footertabname'>Content</Stack>
                                            <Stack spacing={2}>
                                                <Typography className='footertablist'>Categories</Typography>
                                                <Typography className='footertablist'>Packs</Typography>
                                                <Typography className='footertablist'>Offers</Typography>
                                                <Typography className='footertablist'>Plugins</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Stack className='footer-wraper' mt={10}>
                        <Stack className='footer-custom' direction={'row'}>
                            <Typography>© All rights reserved by Pro Trainer in 2022</Typography>
                            <Stack className='e-btn-group' direction={'row'}>
                                <Button className='social-btn'><SocialIcon url="#" network='facebook' bgColor='white' style={{ height: 25, width: 25 }}></SocialIcon></Button>
                                <Button className='social-btn'><SocialIcon url="#" network='twitter'  bgColor='white' style={{ height: 25, width: 25 }}></SocialIcon></Button>
                                <Button className='social-btn'><SocialIcon url="#" network='instagram' bgColor='white' style={{ height: 25, width: 25 }}></SocialIcon></Button>
                                <Button className='social-btn'><SocialIcon url="#" network='linkedin' bgColor='white' style={{ height: 25, width: 25, }}></SocialIcon></Button>
                            </Stack>
                        </Stack>


                    </Stack>

                </Container>

            </footer >
        </Stack >
    )
}

export default AppFooter